import { TextToSpeechComponent } from './text-to-speech/text-to-speech.component';
import { AutocompleteDemoComponent } from './pages/autocomplete-demo/autocomplete-demo.component';
import { MainFooterComponent } from './components/Shared/main-footer/main-footer.component';
import { HeaderNewComponent } from './components/Shared/header-new/header-new.component';
import { FooterNewComponent } from './components/Shared/footer/footer-new.component';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { TimezoneService } from './services/timezone.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { LoadChatDataComponent } from './load-chat/load-chat-data/load-chat-data.component';
import { LoginComponent } from './pages/login/login.component';
import { WebcamModule } from 'ngx-webcam';
import { httpInterceptorProviders } from 'src/interceptor';
import { NoopInterceptor } from 'src/interceptor/interceptor'
import { LoginService } from './services/login.service';
import { MetismenuAngularModule } from "@metismenu/angular";
import { DataTablesModule } from 'angular-datatables';
import { CountdownModule } from 'ngx-countdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { DatePipe } from '@angular/common';
// import { CoursesResolver } from './resolvers/coursesResolver.resolver';
import { Resolver } from './../app/services/resolve.service'
import { ViewCourseDetailsComponent } from './components/view-course-details/view-course-details.component';
import { TrendingCoursesComponent } from './components/trending-courses/trending-courses.component';
import { TestMonialsComponent } from './components/test-monials/test-monials.component';
import { PartnersComponent } from './components/partners/partners.component';
import { AllCategoryCoursesComponent } from './pages/all-category-courses/all-category-courses.component';
import { ERPComponent } from './erp/erp.component';
import { PrivacyPolicyComponent } from './components/organization-details/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/organization-details/terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './components/organization-details/refund-policy/refund-policy.component';
import { ContactUs1Component } from './components/organization-details/contact-us/contact-us1.component';
// import { AboutUsComponent } from './components/organization-details/about-us/about-us.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { TutorHomeComponent } from './pages/tutor-home/tutor-home.component'
import { StudentHomeComponent } from './pages/student-home/student-home.component';
import { StudentSignupComponent } from './pages/student-signup/student-signup.component';
import { MainSliderComponent } from './pages/main-slider/main-slider.component';
import { StaffIdcardHistorylistComponent } from './pages/Administration/Configure/staff-idcard-historylist/staff-idcard-historylist.component'

import { AllCoursesComponent } from './pages/all-courses/all-courses.component';

import { AllCategoriesComponent } from './pages/other-components/all-categories/all-categories.component';
import { EnquiryComponent } from './pages/enquiry/enquiry.component';
import { OpenViduDataComponent } from './open-vidu-data/open-vidu-data.component';
import { VideoRoomComponent } from './open-vidu-data/video-room/video-room.component';
import { OnlineTeachersComponent } from './open-vidu-data/online-teachers/online-teachers.component';
import * as moment from 'moment';
import { ApproveUserComponent } from './pages/approve-user/approve-user.component';
import { StudentRegistrationNewComponent } from './pages/student-registration-new/student-registration-new.component';
import { NewViewCourseDetailsComponent } from './components/new-view-course-details/new-view-course-details.component';
import { NotFoundComponent } from './pages/other-components/not-found/not-found.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { StuHomeComponent } from './pages/stu-home/stu-home.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { FaqListComponent } from './pages/faq-list/faq-list.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgramsComponent } from '../app/pages/Course-setup/programs/programs.component';
import { StudentAvailableProgramsComponent } from './pages/student-available-programs/student-available-programs.component';
import { MyApplicationComponent } from './pages/other-components/my-application/my-application.component';
import { LoginTwoComponent } from './pages/login-two/login-two.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SearchDropdownComponent } from './pages/search-dropdown/search-dropdown.component';
import { SharedModule } from './pages/shared/shared.module';
import { VendorSignupComponent } from './pages/vendor-signup/vendor-signup.component';
import { VendorLoginComponent } from './pages/vendor-login/vendor-login.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function momentAdapterFactory() {
  return adapterFactory(moment);
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    HeaderNewComponent,
    SidebarComponent,
    MaincontentComponent,
    LoginComponent,
    LoadChatDataComponent,
    FooterComponent,
    ViewCourseDetailsComponent,
    NotFoundComponent,
    TrendingCoursesComponent,
    TestMonialsComponent,
    StaffIdcardHistorylistComponent,
    PartnersComponent,
    AllCoursesComponent,
    FooterNewComponent,
    MainFooterComponent,
    AllCategoryCoursesComponent,
    ERPComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    RefundPolicyComponent,
    ContactUs1Component,
    OpenViduDataComponent,
    VideoRoomComponent,
    OnlineTeachersComponent,
    TextToSpeechComponent,
    AboutUsComponent,
    AllCategoriesComponent,
    EnquiryComponent,
    AutocompleteDemoComponent,
    TutorHomeComponent,
    // ApplicationFormComponent,
    StudentHomeComponent,
    StudentSignupComponent,
    MainSliderComponent,
    ApproveUserComponent,
    StudentRegistrationNewComponent,
    NewViewCourseDetailsComponent,
    ForgotPasswordComponent,
    StuHomeComponent,
    StudentDashboardComponent,
    StudentAvailableProgramsComponent,
    MyApplicationComponent,
    LoginTwoComponent,
    AdminDashboardComponent,
    VendorSignupComponent,
    VendorLoginComponent
  ],
  imports: [
    BrowserModule, WebcamModule, NgbTooltipModule,
    AppRoutingModule, AutocompleteLibModule,SharedModule,
    MetismenuAngularModule, PerfectScrollbarModule,SharedModule,
    HttpClientModule, FormsModule, ReactiveFormsModule, DataTablesModule, CountdownModule, BrowserAnimationsModule, NgxSpinnerModule,
    ToastrModule.forRoot({ timeOut: 7000, preventDuplicates: true,progressBar: true,
    closeButton: true }), NgMultiSelectDropDownModule.forRoot(), AngularEditorModule, ChartsModule, DataTablesModule, NgbModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
  ],
  providers: [LoginService, httpInterceptorProviders, NoopInterceptor, Resolver, DatePipe, TimezoneService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
// {provide : LocationStrategy , useClass: HashLocationStrategy}