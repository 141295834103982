import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import { DataTableDirective } from 'angular-datatables';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FileuploadService } from '../../../services/fileupload.service';
import { dataDictionary } from 'src/app/dataDictionary';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-my-application',
  templateUrl: './my-application.component.html',
  styleUrls: ['./my-application.component.css'],
})
export class MyApplicationComponent extends BaseComponent implements OnInit {
  @ViewChild('fileInputRef') fileInputRef: ElementRef;
  @ViewChild('fileInputRefupload1') fileInputRefupload1: ElementRef;
  @ViewChild('fileInputRefPassport') fileInputRefPassport: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  countryId: any;
  meanGrade: string = '';
  countryCodeOne: string = '';
  basicEduSystem: string = '';
  tenantCode: any = localStorage.getItem('TenantCode');
  passportTypeList: Array<any> = [{ id: '1', name: 'National ID' },
  { id: '2', name: 'Passport' }, { id: '3', name: 'Birth Certificate' }
  ];
  selectedValueText: string = '';
  selectedApplicationTypId: any;
  public dtElement: DataTableDirective;
  clusterDropdowns: Array<any> = [];
  myCourseList: Array<any> = [];
  signUpForm: FormGroup;
  UserId = sessionStorage.UserId;
  countryCode: string = '';
  emergencyCountryCode: string = '';
  countyDrpdown: boolean = false;
  nextofKinCountyDrpdown: boolean = false;
  selectedDay: any;
  selectedMonth: any;
  selectedYear: any;
  selectedYearStart: any;
  selectedMonthStart: any;
  selectedDayStart: any;
  County: Array<any> = [];
  selectedYearStartWrk: any;
  selectedMonthStartWrk: any;
  selectedDayStartWrk: any;
  selectedYearEndWrk: any;
  selectedMonthEndWrk: any;
  selectedDayEndWrk: any;
  selectedYearEnd: any;
  selectedMonthEnd: any;
  selectedDayEnd: any;
  makepayment: boolean = true;
  amountPaid: boolean = false;
  // combinedDate: string;
  days: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  startDays: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  wrkStartDays: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  wrkEndDays: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  months: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  years: number[] = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
  company_id = sessionStorage.company_id;
  InstallmentList: Array<any> = [];
  showInstallmentModel: boolean = false;
  EXAM_REG_NO: string = '';
  ADDRESS: string = '';
  Email: any;
  sponserEmail: any;
  editedItemIndex: number = -1;
  wrlExperience: number = -1;
  recommondation: number = -1;
  EDU_DETAIL_ID: any;
  RECOMMANDATION_ID: any;
  WORKEXP_DETAIL_ID: any;
  // ApplicantData: Array<any> = [];
  tableData: Array<any> = [];
  // educationData: Array<any> = [];
  country: Array<any> = [];
  courseList: Array<any> = [];
  MartialStatus: Array<any> = [];
  gender: Array<any> = [];
  dateofbirth: any;
  fileName1: any;
  fileName2: any; fileName3: any; fileName4: any; fileName5: any; isPaid: any;
  aprvId: any;
  private readonly onDestroy = new Subscription();
  userData: Array<any> = [];
  userDetails: any;
  Emailid: string = '';
  INSTITUTION_NAME: string = '';
  QUALIFICATION: string = '';
  LEVEL_OF_STUDY: string = '';
  FROM_PERIOD: string = '';
  TO_PERIOD: string = '';
  ADDITIONAL_INFORMATION: string = '';
  EDUCATION_COUNTRY_ID: string = '';

  educationData: Array<any> = [
    //   {
    //   INSTITUTION_NAME: '',
    //   QUALIFICATION: '',
    //   LEVEL_OF_STUDY: '',
    //   EDUCATION_COUNTRY_ID: '0',
    //   EXAM_REG_NO: '',
    //   PERCENTAGE: 0,
    //   FROM_PERIOD: '',
    //   TO_PERIOD: '',
    //   ADDITIONAL_INFORMATION: '',
    // }
  ];
  WORKEXP_DETAIL_EMPLOYER: string = '';
  WORKEXP_DETAIL_DESIGNATION: string = '';
  WORKEXP_DETAIL_FROM_YEAR: string = '';
  WORKEXP_DETAIL_TO_YEAR: string = '';
  ApplicantData: Array<any> = [
    //   {
    //   WORKEXP_DETAIL_EMPLOYER: '',
    //   WORKEXP_DETAIL_DESIGNATION: '',
    //   WORKEXP_DETAIL_FROM_YEAR: '',
    //   WORKEXP_DETAIL_TO_YEAR: '',
    // }
  ];
  NAME: string = '';
  ORGANIZATION: string = '';
  CITY_TOWN: string = '';
  NATIONALITY: string = '';
  EMAIL_ADDRESS: string = '';
  MOBILE_NUMBER: string = '';
  MOBILENO_CODE_ID: string = '';
  nationalityList: Array<any> = [];
  ClusterDetailsList: Array<any> = [];
  ClusterGradeList: Array<any> = [];
  FROMPERIOD_YEAR: string = '';
  TOPERIOD_YEAR: string = '';
  RefreeData: Array<any> = [
    //   {
    //   NAME: '',
    //   ORGANIZATION: '',
    //   CITY_TOWN: '',
    //   NATIONALITY: '',
    //   EMAIL_ADDRESS: '',
    //   MOBILE_NUMBER: ''
    // }
  ];
  clusters: boolean = false;
  QUALIFICATION_ID: any;
  upload1: any;
  upload2: any;
  upload3: any;
  typesOfFile: object = {
    'Uploaded Material': {
      types: ['pdf'],
      message: 'Please upload the',
    },

  };

  upload_1: any;
  upload_2: any;
  upload_3: any;
  upload4: File;
  upload5: File;
  birthCountry: any;
  nationalityKenya: any;
  subCounty: Array<any> = []; Constituency: Array<any> = [];
  COURSEID: any;
  userComments: any;
  isShow: any = localStorage.getItem('UserBaseSidebarMenu');
  roleId: any = localStorage.getItem('RoleId');


  personalDetailsSaved: boolean = false;
  personalDetailsActive: boolean = true;
  isDisabilityStatus: boolean; isDisabilityStatusNo: boolean;
  showDropdown: boolean = false;
  showReasonTextbox: boolean = false;
  fileName6: any;
  upload_6: any;
  disablePersonalDetails: boolean = false; biographicalInfoSaved: boolean = false;
  nextOfKin: boolean = false;
  educationDetails: boolean = false;
  workExp: boolean = false;
  recomondations: boolean = false;
  programSelection: boolean = false;
  MEAN_GRADE: string = '';
  apptype: any; upload_4: any;
  grades: Array<any> = [];
  private nextEducationId: number = 1;
  @ViewChild('fileInputRefupload2') fileInputRefupload2: ElementRef;
  upload6: File;
  COUNTRY_ID: string = ''; UPLOAD_1: string = '';
  isBooltrue: boolean;
  isFirstLogin: boolean = localStorage.getItem('IsFirstLogin') === 'true';

  constructor(private elementRef: ElementRef, private renderer: Renderer2, CommonService: CommonService, toastr: ToastrService, private route: Router, private fb: FormBuilder,
    private datePipe: DatePipe, private FileuploadService: FileuploadService, private cdr: ChangeDetectorRef) {
    super(CommonService, toastr);

    this.roleId = atob(this.roleId);

    // this.getUserDetails();
    // this.nationalityDrpdown();
    this.selectedValueText = 'Identification Document';
    const paymentValue = localStorage.getItem('payment');

    if (paymentValue === "false") {
      this.makepayment = false;
      this.amountPaid = true;
    } else {
      this.makepayment = true;
      this.amountPaid = false;
    }    // this.isPaid = localStorage.getItem('IsPaid')
  }

  ngOnInit(): void {
    this.uploadForm();
    this.get();
    this.getCourses();
    this.approveStatusCheck();
    this.getCategoryWiseCourses();
    this.getAll();
    this.loadGetClusterGrade();
    this.loadCountry();
    this.generateDropdowns();
    this.getMeanGrades();
    this.getReferrals();
  }


  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }
  generateDaysList(selectedYear: any, selectedMonth: any): any[] {
    const isLeap = this.isLeapYear(selectedYear);
    const maxDays = new Date(selectedYear, selectedMonth, 0).getDate();

    return Array.from({ length: maxDays }, (_, i) => i + 1);
  }
  generateDropdowns() {
    this.months, this.days, this.startDays, this.wrkEndDays, this.wrkStartDays = [];
    this.months = Array.from({ length: 12 }, (_, i) => i + 1);
    this.years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
    this.days = this.generateDaysList((this.selectedYear || this.selectedYearEnd), (this.selectedMonth ||
      this.selectedMonthEnd));
    this.startDays = this.generateDaysList(this.selectedYearStart, this.selectedMonthStart);
    this.wrkStartDays = this.generateDaysList(this.selectedYearStartWrk, this.selectedMonthStartWrk);
    this.wrkEndDays = this.generateDaysList(this.selectedYearEndWrk, this.selectedMonthEndWrk);
    // this.selectedYear = ''
  }

  clearMonthDay() {
    this.selectedMonth = ''; this.selectedDay = '';
  }
  calculateAge(): number {
    const birthDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }

    return age;
  }
  validateAge() {
    const minAge = 16;
    if (!this.isValidDate()) {
      this.toastr.error('The selected date is invalid.');
      this.selectedDay = '';
      this.selectedMonth = '';
      setTimeout(() => {
        this.selectedYear = null;
      }, 20);
      this.cdr.detectChanges();
      return;
    }

    const age = this.calculateAge();
    if (age >= minAge) {
    } else {
      this.toastr.error('Age must be at least 16 years.');
      this.selectedDay = '';
      this.selectedMonth = '';
      setTimeout(() => {
        this.selectedYear = null;
      }, 20); this.cdr.detectChanges();
      return
    }
  }
  isValidDate(): boolean {
    const day = parseInt(this.selectedDay, 10);
    const month = parseInt(this.selectedMonth, 10);
    const year = parseInt(this.selectedYear, 10);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false; // Invalid input
    }

    // Create a date object with the selected values
    const date = new Date(year, month - 1, day);

    // Check if the created date matches the input values (handles month overflow cases)
    return date.getFullYear() === year &&
      date.getMonth() === (month - 1) &&
      date.getDate() === day;
  }
  validateDateRange(item: string): void {
    if (item == 'edu') {
      if (this.QUALIFICATION_ID == '1') {
        const currentYear = new Date().getFullYear();
        this.years = Array.from({ length: currentYear - 1989 + 1 }, (_, i) => currentYear - i);
      }
      const isValidStartDate = this.selectedYearStart;
      const isValidEndDate = this.selectedYearEnd;

      const month = this.selectedMonthStart?.toString().padStart(2, '0');
      const day = this.selectedDayStart?.toString().padStart(2, '0');
      const month1 = this.selectedMonthEnd?.toString().padStart(2, '0');
      const day1 = this.selectedDayEnd?.toString().padStart(2, '0');
      this.FROM_PERIOD = `${isValidStartDate}-${month}-${day}`;
      this.TO_PERIOD = (`${isValidEndDate}-${month1}-${day1}`) == "-00-00" ? '0001-00-00' : `${isValidEndDate}-${month1}-${day1}`;

      let endDate = this.TO_PERIOD == ("-00-00") || this.TO_PERIOD == ("0001-00-00") || this.TO_PERIOD.includes('undefined') ? "" : this.TO_PERIOD;

      const currentDate = new Date(); // Get the current date
      const fromDate = new Date(this.FROM_PERIOD);
      const toDate = new Date(this.TO_PERIOD);

      if (fromDate > currentDate) {
        this.toastr.error("Start date cannot be a future date.");
        return this.educationDates();
      }
      if (toDate > currentDate) {
        this.toastr.error("End date cannot be a future date.");
        return this.educationDates();
      }
      if (endDate != "") {


        if (this.FROM_PERIOD > endDate) {
          this.toastr.error("Start date cannot be greater than end date.");
          return this.educationDates();

        }

        if (this.TO_PERIOD < this.FROM_PERIOD) {
          this.toastr.error("End date cannot be less than start date.");
          return this.educationDates();

        }
      }
    }
    else {
      const isValidStartDate = this.selectedYearStartWrk;
      const isValidEndDate = this.selectedYearEndWrk;

      const month = this.selectedMonthStartWrk?.toString().padStart(2, '0');
      const day = this.selectedDayStartWrk?.toString().padStart(2, '0');
      const month1 = this.selectedMonthEndWrk?.toString().padStart(2, '0');
      const day1 = this.selectedDayEndWrk?.toString().padStart(2, '0');
      this.WORKEXP_DETAIL_FROM_YEAR = `${isValidStartDate}-${month}-${day}`;
      this.WORKEXP_DETAIL_TO_YEAR = (`${isValidEndDate}-${month1}-${day1}`) == "-00-00" ? '0001-00-00' : `${isValidEndDate}-${month1}-${day1}`;
      let endDate = this.WORKEXP_DETAIL_TO_YEAR == ("-00-00") || this.WORKEXP_DETAIL_TO_YEAR == ("0001-00-00") || this.WORKEXP_DETAIL_TO_YEAR.includes('undefined') ? "" : this.WORKEXP_DETAIL_TO_YEAR
      if (endDate != "") {
        if (this.WORKEXP_DETAIL_FROM_YEAR > endDate) {
          this.toastr.error("Start date cannot be greater than end date.");
          return this.wrkExpDates();

        } if (this.WORKEXP_DETAIL_TO_YEAR < this.WORKEXP_DETAIL_FROM_YEAR) {
          this.toastr.error("End date cannot be less than start date.");
          return this.wrkExpDates();

        }
      }
    }

  }

  educationDates() {
    this.selectedYearEnd = ''; this.selectedMonthEnd = ''; this.selectedDayEnd = '';
    this.selectedYearStart = ''; this.selectedMonthStart = ''; this.selectedDayStart = '';
  }
  wrkExpDates() {
    this.selectedYearStartWrk = ''; this.selectedMonthStartWrk = ''; this.selectedDayStartWrk = '';
    this.selectedYearEndWrk = ''; this.selectedMonthEndWrk = ''; this.selectedDayEndWrk = '';

  }

  applicationType(event: any) {
    this.selectedApplicationTypId = event.target?.value;
    if (event.target.value == '2') {
      this.isRecommondations = true;
    }
    // else {
    //   this.isRecommondations = false;
    // }
    if (this.selectedApplicationTypId == '0') {
      this.courseId = '';
    } else {
      localStorage.setItem('cName', '');
      this.courseList = [];
      this.CommonService.getCall(`CourseSchedule/GetAllCoursesByCategoryId/${0}/${0}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
        this.courseList = res.dtCourseScehdule.filter(e => e.COURSE_GRADUATION == parseInt(this.selectedApplicationTypId));

      })
      // this.getCategoryWiseCourses();
      if (this.selectedApplicationTypId == '2') {
        this.clusters = false;
        // this.basicEduSystem = '';
        // this.meanGrade = '';
        this.signUpForm.get('BASIC_EDUCATION_SYSTEM_OTHER').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT1_NAME').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT1_GRADE').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT2_NAME').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT2_GRADE').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT3_NAME').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT3_GRADE').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT4_NAME').setValue('');
        this.signUpForm.get('CLUSTER_SUBJECT4_GRADE').setValue('');
        this.courseId = '';

      } else {
        // this.clusters = true;
      }
    }

  }
  validateStartDate(item: any) {
    if (item == 'w') {
      const startDate = new Date(this.WORKEXP_DETAIL_FROM_YEAR);
      const endDate = new Date(this.WORKEXP_DETAIL_TO_YEAR);

      if (endDate < startDate) {
        this.toastr.error("Start date cannot be greater than end date");
        this.WORKEXP_DETAIL_FROM_YEAR = '';
      }
      return
    } else {
      const startDate = new Date(this.FROM_PERIOD);
      const endDate = new Date(this.TO_PERIOD);

      if (endDate < startDate) {
        this.toastr.error("Start date cannot be greater than end date");
        this.FROM_PERIOD = '';
      }
    }


  }
  loadClusterDetails(event: any) {
    this.activeSpinner();
    let cluster = this.courseList.find(e => e.COURSE_ID == event)?.IS_CLUSTER;
    if (cluster) {
      this.clusters = true;
    } else {
      this.clusters = false;
    }
    const numberOfCalls = 4;
    for (let i = 1; i <= numberOfCalls; i++) {
      let clusterId = i;
      let payload = {
        "TNT_CODE": localStorage.getItem('TenantCode'),
        "COURSE_ID": event?.target?.value || this.userDetails?.get_cluster?.COURSE_ID,
        "CLUSTER_ID": clusterId,
      }

      this.CommonService.postCall('ClustersMaster/GetClusterDetailsList', payload).subscribe((res: any) => {
        this.clusterDropdowns[clusterId - 1] = res;
        if (this.clusterDropdowns.length === numberOfCalls) {

          this.deactivateSpinner();
        }
      });

    }
  }

  loadGetClusterGrade() {
    this.activeSpinner();
    this.CommonService.postCall('ClustersMaster/GetClusterGradeList', { TNT_CODE: localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.ClusterGradeList = res;
      if (this.userDetails?.get_cluster != null) {
        setTimeout(() => {
          const { CLUSTER_SUBJECT1_GRADE1, CLUSTER_SUBJECT2_GRADE2, CLUSTER_SUBJECT3_GRADE3, CLUSTER_SUBJECT4_GRADE4 } = this.userDetails?.get_cluster;
          this.signUpForm.patchValue({
            CLUSTER_SUBJECT1_GRADE: CLUSTER_SUBJECT1_GRADE1,
            CLUSTER_SUBJECT2_GRADE: CLUSTER_SUBJECT2_GRADE2,
            CLUSTER_SUBJECT3_GRADE: CLUSTER_SUBJECT3_GRADE3,
            CLUSTER_SUBJECT4_GRADE: CLUSTER_SUBJECT4_GRADE4,
          });
        }, 1000);
      }

      this.deactivateSpinner();
    }, (err: any) => {
      this.deactivateSpinner();
    })
  }
  validateEndDate(item: any) {
    if (item == 'w') {
      const startDate = new Date(this.WORKEXP_DETAIL_FROM_YEAR);
      const endDate = new Date(this.WORKEXP_DETAIL_TO_YEAR);

      if (endDate < startDate) {
        this.toastr.error("End date cannot be less than start date");
        this.WORKEXP_DETAIL_TO_YEAR = '';
      }
      return
    } else {
      const startDate = new Date(this.FROM_PERIOD);
      const endDate = new Date(this.TO_PERIOD);

      if (endDate < startDate) {
        this.toastr.error("End date cannot be less than start date");
        this.TO_PERIOD = '';
      }
    }

  }
  loadCountry() {
    this.activeSpinner();
    this.country = [];
    this.CommonService.postCall('ClustersMaster/GetCountryList', {}).subscribe((res: any) => {
      this.country = res;
      setTimeout(() => {
        // Set countryId and countryCode based on conditions
        this.signUpForm.get('COUNTRY_ID').setValue(this.userDetails?.result?.country_id == 0 || null
          ? this.country.find((e: any) => e.COUNTRY_NAME == "Kenya")?.COUNTRY_ID
          : this.userDetails?.result?.country_id)
        // this.countryId = this.userDetails?.result?.country_id == 0 || null
        //   ? this.country.find((e: any) => e.COUNTRY_NAME == "Kenya")?.COUNTRY_ID
        //   : this.userDetails?.result?.country_id;

        // this.emergencyCountryId = this.userDetails?.result?.countryid_of_emergency == 0 || null
        //   ? this.country.find((e: any) => e.COUNTRY_NAME == "Kenya")?.COUNTRY_ID
        //   : this.userDetails?.result?.countryid_of_emergency;

        // this.birthCountry = this.userDetails?.result?.birth_country_id == 0 ?
        //   this.country.find((e: any) => e.COUNTRY_NAME == "Kenya")?.COUNTRY_ID : this.userDetails?.result?.birth_country_id;

        // Set countryCode (this appears to be redundant, as it's set the same way as countryId)
        this.countryCode = this.userDetails?.result?.country_id == 0 || null
          ? this.country.find((e: any) => e.COUNTRY_NAME == "Kenya")?.COUNTRY_ID
          : this.userDetails?.result?.country_id;
        this.countryCodeOne = this.countryCode;

        // Call the countyDropdowns function with the countryCode
        this.countyDropdowns((this.countryCode || this.countryCodeOne), 'personalDetailsCountry');

        // Check if emergency country ID is not null or 0 and set emergencyCountryCode
        if (this.userDetails?.result?.countryid_of_emergency != null || 0) {
          this.emergencyCountryCode = this.userDetails?.result?.countryid_of_emergency == 0 || null
            ? this.country.find((e: any) => e.COUNTRY_ID == this.userDetails?.result?.countryid_of_emergency)?.COUNTRY_ID
            : this.userDetails?.result?.countryid_of_emergency;
        }
      }, 1000);

      this.deactivateSpinner();

    })
  }
  changeCountry(event: any, item: any) {
    if (item == 'personalDetailsCountry') {
      this.countryCode = this.country.find((e: any) => e.COUNTRY_ID == event)?.COUNTRY_ID;
      this.countryCodeOne = this.countryCode;
      return this.countyDropdowns((this.countryCode || this.countryCodeOne), item);

    } else {
      this.emergencyCountryCode = this.country.find((e: any) => e.COUNTRY_ID == event)?.COUNTRY_ID;
      this.countyDropdowns(this.emergencyCountryCode, item);

    }

  }
  countyDropdowns(item: any, selectedCountry: String) {
    // this.country.sort((a, b) => a.COUNTRY_CONNECTING_CODE.localeCompare(b.COUNTRY_CONNECTING_CODE));
    this.country = this.country.sort((a: any, b: any) => a?.COUNTRY_NAME?.localeCompare(b?.COUNTRY_NAME));
    // this.country =[];
    let county = this.country.find((e: any) => e.COUNTRY_ID == item)?.COUNTRY_NAME;
    this.County = [];
    if (county == 'Kenya') {
      if (selectedCountry == 'personalDetailsCountry') {
        this.countyDrpdown = true;
      } else {
        this.nextofKinCountyDrpdown = true;
      }
      this.CommonService.postCall('ClustersMaster/GetCountiesListByKenya', { "COUNTRY_NAME": county }).subscribe((res: any) => {
        this.County = res;
      }, (err: any) => {
        this.toastr.error(err.error ? err.error : "No County's found!");
      })
      return
    } else if (county != 'Kenya' && selectedCountry == 'personalDetailsCountry') {
      this.countyDrpdown = false;
      this.signUpForm.get('COUNTIES_ID').setValue('');
      // this.getCountyDropdowns(county, this.userDetails?.result.counties_id_of_emergency)
    }
    else {
      // this.countyDrpdown = false;
      this.nextofKinCountyDrpdown = false;
      this.signUpForm.get('COUNTIES_ID_OF_EMERGENCY').setValue('');
      this.getCountyDropdowns(this.userDetails?.result.counties_id, county)

    }
  }
  subCounties(SubCountyId: any) {
    this.CommonService.postCall('ClustersMaster/GetSubCountiesList', { "counties_id": SubCountyId }).subscribe((res: any) => {
      this.subCounty = res;
      setTimeout(() => {
        this.signUpForm.patchValue({
          "SUBCOUNTIES_ID": this.userDetails?.result?.subcounties_id
        })
      }, 2000);

    }, (err: any) => {
      return this.toastr.error(err.error ? err.error : "No County's found!");
    })
    this.CommonService.postCall('ClustersMaster/GetCountyconstituenciesList', { "counties_id": SubCountyId }).subscribe((res: any) => {
      this.Constituency = res;
      setTimeout(() => {
        this.signUpForm.patchValue({
          "COUNTIES_CONSTITUENCIES_ID": this.userDetails?.result?.counties_constituencies_id
        })
      }, 2000);

    }, (err: any) => {
      return this.toastr.error(err.error ? err.error : "No County's found!");
    })
  }
  nextTab() {
    const activeTab = document.querySelector('.nav-tabs .active')?.closest('li');

    if (activeTab) {
      const nextTab = activeTab.nextElementSibling;

      if (nextTab) {
        nextTab.querySelector('a')?.click();
      }
    }
  }

  previousTab(getTabName: string) {
    if (getTabName == 'persnal') {
      this.personalDetailsSaved = false;
    } else if (getTabName == 'bio') {
      this.biographicalInfoSaved = false;
    } else if (getTabName == 'program') {
      this.nextOfKin = false;
    } else if (getTabName == 'edu') {
      this.educationDetails = false;
      if (this.apptype == '1') {
        this.Referrals = false;
      }
    } else if (getTabName == 'wrk') {
      this.workExp = false;
    } else if (getTabName == 'rec') {
      this.recomondations = false;
      this.Referrals = false;
    } else if (getTabName == 'ref') {
      this.Referrals = false;
    }
    else if (getTabName == 'appInfo') {
      this.applicationProgram = false;
    }
    const activeTab = document.querySelector('.nav-tabs .active')?.closest('li');
    const prevTab = activeTab.previousElementSibling;
    if (prevTab) {
      prevTab?.querySelector('a')?.click();
      // const prevTabLinkEl = prevTab.querySelector('a');
      // if (prevTabLinkEl) {
      //   this.renderer.selectRootElement(prevTabLinkEl).click();
      // }
    }
  }
  nationalityDrpdown() {
    this.nationalityList = [];
    this.CommonService.postCall('ClustersMaster/GetNationalityList', {}).subscribe((res: any) => {
      this.nationalityList = res;
      setTimeout(() => {
        this.nationalityKenya = this.userDetails?.result?.nationality == 0 || this.userDetails?.result?.nationality == null
          ? this.nationalityList.find((e: any) => e.NAN_CODE == "Kenyan")?.NAN_ID
          : this.userDetails?.result.nationality;
      }, 200);
    })
  }

  uploadForm() {
    this.signUpForm = this.formBuilder.group({
      "FIRSTNAME": ['', Validators.required],
      "MIDDLENAME": [''],
      "LASTNAME": ['', Validators.required],
      "COUNTRY_ID": [null, Validators.required],
      "COUNTIES_ID": [''],
      "SUBCOUNTIES_ID": [''],
      "COUNTIES_CONSTITUENCIES_ID": [''],
      "POSTAL_ADDRESS": [''],
      "PHYSICAL_ADDRESS": [''],
      "POSTAL_CODE": [''],
      "NEAREST_TOWN": [''],
      "MOBILENO_CODE_ID": ['', Validators.required],
      "MOBILENO2_CODE_ID": [''],
      "MOBILENO": ['', Validators.required],
      "MOBILENO2": [''],

      "GENDER": [0, Validators.required],
      "MARTIALSTATUS": [0],
      "BIRTH_COUNTRY_ID": ['0', Validators.required],
      "UPLOAD_4": [''],
      "DOB": ['', Validators.required],
      "NATIONALITY": ['0', Validators.required],
      "ID_PASSPORT_TYPE": [0, Validators.required],
      "ID_PASSPORT": ['', Validators.required],
      "identity_document_upload": ['', Validators.required],
      "is_disability": [false],
      "type_of_disability": [''],
      "disability_description": [''],
      "orphan": ['0'],

      "NAME_OF_EMERGENCY": ['', Validators.required],
      "RELATIONSHIPNAME_OF_EMERGENCY": ['0', Validators.required],
      "MOBILE_NO_EMERGENCY": ['', Validators.required],
      "ADDRESS_OF_EMERGENCY": [''],
      "POSTALCODE_OF_EMERGENCY": [''],
      "COUNTIES_ID_OF_EMERGENCY": [''],
      "TOWNCITY_OF_EMERGENCY": [''],
      "COUNTRYID_OF_EMERGENCY": [0],
      "EMAILID_OF_EMERGENCY": [''],
      "EMERGENCY_MOBILENO_CODE_ID": ['0', Validators.required],

      "UPLOAD_1": ['', Validators.required],
      "UPLOAD_3": [''],
      "IS_RPL": [null, Validators.required],

      "IS_KUCCPS": [false],
      "COURSE_INTERESTED_IN": [''],
      "PROGRAMME_CATEGORY_ID": [],


      "SPONSORS_NAME": [''],
      "SPONSORS_MOBILE": [''],
      "SPONSORS_EMAIL": [''],
      // "MODE_OF_STUDY": ['0', Validators.required],

      "APPLICATION_TYPE": ['0', Validators.required],
      "BASIC_EDUCATION_SYSTEM": ['0'],
      "BASIC_EDUCATION_SYSTEM_OTHER": [''],
      "MEAN_GRADE": [''],
      "CLUSTER_SUBJECT1_NAME": [''],
      "CLUSTER_SUBJECT2_NAME": [''],
      "CLUSTER_SUBJECT3_NAME": [''],
      "CLUSTER_SUBJECT4_NAME": [''],
      "CLUSTER_SUBJECT1_GRADE": [''],
      "CLUSTER_SUBJECT2_GRADE": [''],
      "CLUSTER_SUBJECT3_GRADE": [''],
      "CLUSTER_SUBJECT4_GRADE": [''],
      "ENGLISH_PROFICIENCY": ['0', Validators.required],
      "COMPUTING_SKILLS_PROFICIENCY": ['0', Validators.required],

    })
  }
  disableDate() {
    return false;
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  agevalidate(event: any) {
    let date = event.target.value;
    let dob = new Date(date);
    let timeDiff = Math.abs(Date.now() - dob.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    if (age < 16) {
      this.toastr.warning("Age Should be greater than 16");
      this.dateofbirth = '';
    }
  }
  approveStatusCheck() {
    this.CommonService.postCall('Registration/LoadpaymentOrApproveStatus', { "APPL_PAYDET_USER_ID": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.aprvId = res?.IS_APPROVED;
      this.isPaid = res?.APPL_PAYDET_ISPAID;
    })
    // this.nationalityDrpdown();
  }
  enableOrDisabledSpinner(flag: boolean = true) {
    flag ? this.CommonService.activateSpinner() : this.CommonService.deactivateSpinner();
  }
  closeModal(event: any) {
    document.getElementById('md_close')?.click();
  }
  AcceptMthd() {
    this.activeSpinner();
    let payload = {
      // "IS_APPROVED": 4,
      // "CourseId": 0,
      // "CREATEDBY": localStorage.getItem('UserId'),
      // "ModifiedBy": localStorage.getItem('UserId'),
      "user_id": localStorage.getItem('UserId'),
      // "CourseScheduleId": 0,
      "tnt_code": localStorage.getItem('TenantCode'),
      // "USER_NAME": sessionStorage.getItem('USERNAME')
    }

    // this.CommonService.postCall('Registration/UpdateAproverStatus', payload).subscribe((res: any) => {
    //   this.toastr.success("You have been Accepted!");
    //   this.approveStatusCheck();
    //   this.getUserDetails();
    //   this.deactivateSpinner();
    // }, (err: any) => {
    //   this.toastr.error(err.error ? err.error : 'Not Updated!');
    //   this.deactivateSpinner();
    // })
    this.CommonService.postCall('Registration/StudentAcceptance', payload).subscribe((res: any) => {
      this.toastr.success("You have been Accepted!");
      this.approveStatusCheck();
      // this.getUserDetails();
      this.get();
      this.deactivateSpinner();
    }, (err: any) => {
      this.toastr.error(err.error ? err.error : 'Not Updated!');
      this.deactivateSpinner();
    })
  }
  get() {
    this.CommonService.postCall('Registration/ApplicationView', { "USER_ID": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.userData = res;
    })
  }
  makePayment() {
    // this.route.navigate(['HOME/components/payment']);
    let payLoad = [{
      CourseId: this.userDetails.result.courseid,
      CREATEDBY: localStorage.getItem('UserId'),
      TNT_CODE: localStorage.getItem('TenantCode'),
      UserId: localStorage.getItem('UserId'),
      CourseScheduleId: 0,
      ModifiedBy: localStorage.getItem('UserId'),
      STUDENT_PAYMENT_COURSE_FEE: this.userDetails.result.course_fees,
      STUDENT_PAYMENT_TOTAL_AMOUNT_PAID: 1,
      STUDENT_PAYMENT_CSI_INSTALLMENT_ID: 0
    }];
    this.CommonService.postCall('Enroll/CreateEnroll', payLoad).subscribe((res: any) => {
      // this.enroll = res;
      if (res) {
        document.getElementById('md_close')?.click();
        this.toastr.success('Payment Successfully');
        this.makepayment = false;
        localStorage.setItem('payment', (this.makepayment).toString());
        this.amountPaid = true;
        setTimeout(() => {
          this.route.navigate(['/HOME/stuHome']);
        }, 200);

      }
      // document.getElementById('md_close').click();
      // this.toastr.success('Payment Successfully');
      // this.route.navigate(['HOME/my-courses']);

    });
  }
  edit(item: any) {
    $("#editPopup").modal("show");
    $('#editPopup').appendTo("body")
    this.activeSpinner();
    this.courseId = item;
    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": localStorage.getItem('UserId'), "COURSE_ID": item }).subscribe((res: any) => {
      this.userDetails = res;
      setTimeout(() => {
        this.loadClusterDetails(this.userDetails?.get_cluster?.COURSE_ID);
        // this.changeCountry(this.userDetails?.result?.country_id,this.userDetails?.result?.birth_country_id)
        this.getCategoryWiseCourses();
        this.showportfolio(this.userDetails?.lst[0]?.IS_RPL)
        this.signUpForm.patchValue({
          // "NAME_OF_EMERGENCY": this.userDetails?.result.name_of_emergency,
          "FIRSTNAME": this.userDetails?.result?.firstname,
          "LASTNAME": this.userDetails?.result?.lastname,
          // "RELATIONSHIPNAME_OF_EMERGENCY": this.userDetails?.result.relationshipname_of_emergency,
          // "MOBILE_NO_EMERGENCY": this.userDetails?.result.mobile_no_emergency,
          // "ADDRESS_OF_EMERGENCY": this.userDetails?.result.address_of_emergency,
          // "POSTALCODE_OF_EMERGENCY": this.userDetails?.result.postalcode_of_emergency,
          // "TOWNCITY_OF_EMERGENCY": this.userDetails?.result.towncity_of_emergency,
          // "COUNTRYID_OF_EMERGENCY": this.userDetails?.result.countryid_of_emergency,
          // "EMAILID_OF_EMERGENCY": this.userDetails?.result.emailid_of_emergency,
          "MIDDLENAME": this.userDetails?.result.middlename,
          // "POSTAL_CODE": this.userDetails?.result.postal_code,
          // "MOBILENO2": this.userDetails?.result.mobileno2,
          // "POSTAL_ADDRESS": this.userDetails?.result.postal_address,
          // "PHYSICAL_ADDRESS": this.userDetails?.result.physical_address,
          // "NATIONALITY": this.userDetails?.result.nationality,
          // "NEAREST_TOWN": this.userDetails?.result.nearest_town,
          // "ID_PASSPORT": this.userDetails?.result.id_passport,
          // "BIRTH_COUNTRY_ID": this.userDetails?.result.birth_country_id,
          "GENDER": this.userDetails?.result.gender,
          "MOBILENO": this.userDetails?.result.mobileno,
          // "MARTIALSTATUS": this.userDetails?.result.martialstatus,
          // "ID_PASSPORT_TYPE": this.userDetails?.result?.id_passport_type,
          "PROGRAMME_CATEGORY_ID": this.userDetails?.result.programme_category_id,
          // "IS_KUCCPS": this.userDetails?.get_cluster?.IS_KUCCPS,
          // "MODE_OF_STUDY": this.userDetails?.result.mode_of_study,
          "SPONSORS_NAME": this.userDetails?.result.sponsors_name,
          "SPONSORS_MOBILE": this.userDetails?.result.sponsors_mobile,
          "SPONSORS_EMAIL": this.userDetails?.result.sponsors_email,
          // "orphan": this.userDetails?.result?.orphan,
          // "IS_RPL": this.userDetails?.result.is_rpl,
          "APPLICATION_TYPE": this.userDetails?.get_cluster != null ? this.userDetails?.get_cluster?.APPLICATION_TYPE : parseInt(this.apptype),
          // "BASIC_EDUCATION_SYSTEM": this.userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM,
          // "MEAN_GRADE": this.userDetails?.get_cluster?.MEAN_GRADE,
          "COMPUTING_SKILLS_PROFICIENCY": this.userDetails?.get_cluster?.COMPUTING_SKILLS_PROFICIENCY,
          "ENGLISH_PROFICIENCY": this.userDetails?.get_cluster?.ENGLISH_PROFICIENCY,
          "IS_RPL": this.userDetails?.lst[0]?.IS_RPL,
          // "APPLICATION_TYPE": this.userDetails?.get_cluster?.APPLICATION_TYPE,
          // "MODE_OF_STUDY": this.userDetails?.get_cluster?.MODE_OF_STUDY,
          "MOBILENO_CODE": this.userDetails?.result?.mobileno_code_id == null ? this.countryCode : this.userDetails?.result?.mobileno_code_id,
          // "MOBILENO2_CODE": this.userDetails?.result?.mobileno2_code_id == null ? this.countryCodeOne : this.userDetails?.result?.mobileno2_code_id,
          // "EMERGENCY_MOBILENO_CODE_ID": this.userDetails?.result?.emergency_mobileno_code_id,
          // "UPLOAD_4": this.userDetails?.result?.upload_4,
          // "COURSE_ID":
          // "UPLOAD_1":this.signUpForm.get('UPLOAD_1').setValue('this.userDetails?.result.upload_1')
        })
        if (this.userDetails?.result?.is_cluster) {
          this.clusters = true;
          // this.loadClusterDetails(this.userDetails.get_cluster);
        }
        // this.loadCountry();

        if (this.userDetails?.result?.country_id != 0) {
          this.signUpForm.patchValue({
            "COUNTRY_ID": this.userDetails?.result.country_id,
          })
          // this.countryId = this.userDetails?.result.country_id
        }
        // this.loadCountry();
        setTimeout(() => {

          const { country_id, counties_id, countryid_of_emergency, counties_id_of_emergency } = this.userDetails?.result;
          const [personalDetails_Country, nextofKin_Country] = [this.getCountryName(country_id), this.getCountryName(countryid_of_emergency)]

          if (personalDetails_Country == "Kenya" && nextofKin_Country == "Kenya") {
            [this.countyDrpdown, this.nextofKinCountyDrpdown] = [true, true];
            this.getCountyDropdowns(counties_id, counties_id_of_emergency);
            this.subCounties(this.userDetails?.result?.counties_id)
          }
          else if (personalDetails_Country == "Kenya" && nextofKin_Country != "Kenya") {
            this.countyDrpdown = true;
            this.getCountyDropdowns(counties_id);
            this.subCounties(this.userDetails?.result?.counties_id)
          }
          else if (nextofKin_Country == "Kenya" && personalDetails_Country != "Kenya") {
            this.nextofKinCountyDrpdown = true;
            this.getCountyDropdowns(counties_id_of_emergency, 0, true);
          }
          this.countryCode = this.userDetails?.result?.country_id == 0 || null
            ? this.country.find((e: any) => e.COUNTRY_NAME == "Kenya")?.COUNTRY_ID
            : this.userDetails?.result?.country_id;
          this.countryCodeOne = this.countryCode;
        }, 2000);

        // if (this.userDetails?.result?.counties_id != 0) {
        //   setTimeout(() => {
        //     this.signUpForm.patchValue({
        //       "COUNTIES_ID": this.userDetails?.result.counties_id,
        //     })
        //     this.countyDropdowns(this.userDetails?.result.country_id, 'personalDetailsCountry');
        //   }, 200);

        // }
        // if (this.userDetails?.result?.counties_id_of_emergency != 0) {
        //   setTimeout(() => {

        //     this.signUpForm.patchValue({
        //       "COUNTIES_ID_OF_EMERGENCY": this.userDetails?.result.counties_id_of_emergency,
        //     })
        //     this.countyDropdowns(this.userDetails?.result.countryid_of_emergency, 'nextofKin');
        //   }, 200);

        // }
        // "DOB": this.userDetails?.result.dob,
        if (this.userDetails?.get_cluster != null) {
          this.courseId = this.userDetails?.get_cluster?.COURSE_ID;

          this.getAllClusterDropdowns();
          this.loadGetClusterGrade();
        }
        // this.signUpForm.get('DOB').setValue(this.userDetails?.result.dob);
        // this.changeCountry(this.userDetails?.result?.country_id, this.userDetails?.result?.mobile_no_emergency);
        this.RefreeData = this.userDetails?.lst_recommandations;
        const parts = this.userDetails?.result.dob.split('-');

        this.selectedYear = parts[0];
        this.selectedMonth = parseInt(parts[1]);
        this.selectedDay = parseInt(parts[2]);
        this.generateDropdowns();
        this.generateDaysList(this.selectedYear, this.selectedMonth)
        if (this.userDetails.lst.length != 0) {
          this.educationData = this.userDetails?.lst;
          this.signUpForm.get('UPLOAD_3')?.setValue(this.userDetails?.lst[0]?.UPLOAD_3);
        }
        this.signUpForm.get('UPLOAD_1')?.clearValidators();
        this.signUpForm.get('UPLOAD_1')?.updateValueAndValidity();
        this.signUpForm.get('UPLOAD_3')?.clearValidators();
        this.signUpForm.get('UPLOAD_3')?.updateValueAndValidity();
        this.signUpForm.get('UPLOAD_4')?.clearValidators();
        this.signUpForm.get('UPLOAD_4')?.updateValueAndValidity();
        const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
        this.upload_1 = this.userDetails?.result.upload_1?.replace(prefixToRemove, '');
        this.upload_2 = this.userDetails?.result.upload_4?.replace(prefixToRemove, '');
        this.upload_3 = this.userDetails?.lst[0]?.UPLOAD_3?.replace(prefixToRemove, '');
        this.upload_6 = this.userDetails?.result.identity_document_upload?.replace(prefixToRemove, '');
        if (this.userDetails.lstt.length != 0) {
          this.ApplicantData = this.userDetails?.lstt;
          this.ApplicantData.forEach(data => {
            data.WORKEXP_DETAIL_FROM_YEAR = new Date(data.WORKEXP_DETAIL_FROM_YEAR).toISOString().split('T')[0];
            data.WORKEXP_DETAIL_TO_YEAR = new Date(data.WORKEXP_DETAIL_TO_YEAR).toISOString().split('T')[0];
          });
        }
        if (this.userDetails.result?.is_disability == true) {
          this.showDropdown = true;
          this.isDisabilityStatus = this.userDetails.result?.is_disability
          this.signUpForm.patchValue({
            "type_of_disability": this.userDetails?.result.type_of_disability,
          })
          if (this.userDetails?.result.type_of_disability == 5) {
            this.showReasonTextbox = true;
            this.signUpForm.patchValue({
              "disability_description": this.userDetails?.result.disability_description,
            })
          }
        }
        this.radioChanged(this.userDetails?.result?.is_disability)
        this.selectedReferral = this.userDetails?.result?.referral_platform_id;
        this.changeReferral(this.userDetails?.result.referral_platform_id);
        if (this.userDetails?.get_cluster?.APPLICATION_TYPE == 2) {
          this.isRecommondations = true;
        }
      }, 1000);
      this.nationalityDrpdown();
      this.deactivateSpinner();
    }, (err: any) => {
      this.toastr.error(err.error ? err.error : 'Failed');
      this.deactivateSpinner();
    })
  }
  documentTypeId(event: any) {
    let Selectedid = event.target.value;
    if (Selectedid == '0') {
      this.selectedValueText = Selectedid == '0' ? 'Identification Document' : this.selectedValueText;
    } else {

      this.selectedValueText = this.passportTypeList.find(e => e.id == Selectedid)?.name;
    }
  }

  getCountryName(id: number): string {
    const country = this.country.find((e: any) => e.COUNTRY_ID === id);
    return country ? country.COUNTRY_NAME : '';
  }
  getCountyDropdowns(id: number, id2: number = 0, isNextOfKin = false) {
    this.CommonService.postCall('ClustersMaster/GetCountiesListByKenya', { "COUNTRY_NAME": 'Kenya' }).subscribe((res: any) => {
      this.County = res;
      setTimeout(() => {
        if (isNextOfKin) {
          this.signUpForm.patchValue({ "COUNTIES_ID_OF_EMERGENCY": id });
        }
        else if (id2 > 0) {
          this.signUpForm.patchValue({ "COUNTIES_ID": id, "COUNTIES_ID_OF_EMERGENCY": id2 });
        }
        else {
          this.signUpForm.patchValue({ "COUNTIES_ID": id });
        }

      }, 2000);
    }, (err: any) => {
      this.toastr.error(err.error ? err.error : "No County's found!");
    })
    // }
  }

  getAllClusterDropdowns() {
    let payload = {
      TNT_CODE: localStorage.TenantCode,
      COURSE_ID: this.userDetails?.get_cluster?.COURSE_ID,
      CLUSTER_ID: 1,
    };

    const clusterId_1 = this.CommonService.postCall('ClustersMaster/GetClusterDetailsList', payload);
    const clusterId_2 = this.CommonService.postCall('ClustersMaster/GetClusterDetailsList', { ...payload, CLUSTER_ID: 2 });
    const clusterId_3 = this.CommonService.postCall('ClustersMaster/GetClusterDetailsList', { ...payload, CLUSTER_ID: 3 });
    const clusterId_4 = this.CommonService.postCall('ClustersMaster/GetClusterDetailsList', { ...payload, CLUSTER_ID: 4 });

    forkJoin([clusterId_1, clusterId_2, clusterId_3, clusterId_4]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          const assignList = {
            '0': () => this.clusterDropdowns[ind] = list,
            '1': () => this.clusterDropdowns[ind] = list,
            '2': () => this.clusterDropdowns[ind] = list,
            '3': () => this.clusterDropdowns[ind] = list,
          }
          assignList[ind]();
          setTimeout(() => {
            const { CLUSTER_SUBJECT1_NAME1, CLUSTER_SUBJECT2_NAME2, CLUSTER_SUBJECT3_NAME3, CLUSTER_SUBJECT4_NAME4 } = this.userDetails?.get_cluster;
            this.signUpForm.patchValue({
              CLUSTER_SUBJECT1_NAME: CLUSTER_SUBJECT1_NAME1,
              CLUSTER_SUBJECT2_NAME: CLUSTER_SUBJECT2_NAME2,
              CLUSTER_SUBJECT3_NAME: CLUSTER_SUBJECT3_NAME3,
              CLUSTER_SUBJECT4_NAME: CLUSTER_SUBJECT4_NAME4,
            });
          }, 1000);

        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
        // this.signUpForm.get('CLUSTER_SUBJECT1_NAME').setValue(this.userDetails?.get_cluster?.CLUSTER_SUBJECT1_NAME1);
      }
    });
  }
  getAll() {
    let types = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Gender });//Gender
    let martialStatus = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.MaritalStatus })//MartialStatus
    let country = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Country });
    forkJoin([types, martialStatus, country]).subscribe((res) => {
      [this.gender, this.MartialStatus, this.country] = [...res]
    })

  }
  getCategoryWiseCourses() {
    // const { company_id } = sessionStorage;
    [this.courseList] = [[]];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall(`CourseSchedule/GetAllCoursesByCategoryId/${0}/${0}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {

      this.courseList = res?.dtCourseScehdule;
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  validateDates(event: any) {
    // row.TO_PERIOD = null;
    let value = event?.target.value;
    this.isToDateInvalid(value)
    const rowIndex = this.educationData.indexOf(value);
    if (rowIndex >= 0) {
      for (let i = 0; i < rowIndex; i++) {
        const previousRow = this.educationData[i];
        if (previousRow.TO_PERIOD && value.FROM_PERIOD) {
          const previousTo = new Date(previousRow.TO_PERIOD);
          const currentFrom = new Date(value.FROM_PERIOD);
          if (currentFrom <= previousTo) {

          }
        }
      }
    }
  }
  isToDateInvalid(row: any): boolean {
    if (!row.FROM_PERIOD || !row.TO_PERIOD) {
      return false;
    }

    const from = new Date(row.FROM_PERIOD);
    const to = new Date(row.TO_PERIOD);

    return from > to;
  }
  update(form: any, IS_SUBMIT: any) {
    this.activeSpinner();
    this.saveReferral()
    if (this.educationData.length == 0) {
      this.toastr.error("Please enter mandatory fields in education background");
      return this.deactivateSpinner();
    }
    //  else if (this.RefreeData.length == 0) {
    //   this.toastr.error("Please enter mandatory fields in Recommendation");
    //   return this.deactivateSpinner();
    // } 
    else {
      let value = form.value;
      // if (value.IS_RPL == "null") {
      //   this.toastr.warning("IS RPL is mandatory in documents upload");
      //   return this.deactivateSpinner();
      // }
      const month = this.selectedMonth?.toString().padStart(2, '0');
      const day = this.selectedDay?.toString().padStart(2, '0');
      let selectedDob = `${this.selectedYear}-${month}-${day}`;
      let countyName = this.country.find((e: any) => e.COUNTRY_ID == (value.COUNTRY_ID || this.userDetails?.result?.country_id))?.COUNTRY_NAME;
      let countryNameOfEmergency = this.country.find((e: any) => e.COUNTRY_ID == (value.COUNTRYID_OF_EMERGENCY || this.userDetails?.result?.counties_id_of_emergency))?.COUNTRY_NAME;
      let countyId: any; let subCountyId: any;
      let ConstituencyId: any;
      if (countyName === 'Kenya') {
        countyId = value.COUNTIES_ID || this.userDetails?.result?.counties_id;
        subCountyId = value.SUBCOUNTIES_ID == '' ? value.SUBCOUNTIES_ID : this.userDetails?.result?.subcounties_id;
        ConstituencyId = value.COUNTIES_CONSTITUENCIES_ID == '' ? value.COUNTIES_CONSTITUENCIES_ID : this.userDetails?.result?.counties_constituencies_id;

        if ((countyId == "" || countyId == 0)) {
          this.toastr.warning("County is mandatory!");
          return this.deactivateSpinner()
        }
        //  else if (subCountyId == "" || subCountyId == 0) {
        //   this.toastr.warning("Sub County is mandatory!");
        //   return this.deactivateSpinner()

        // } else if (ConstituencyId == "" || ConstituencyId == 0) {
        //   this.toastr.warning("Constituency is mandatory!");
        //   return this.deactivateSpinner()

        // }
      } else {
        countyId = 0;
        subCountyId = 0;
        ConstituencyId = 0;
      }
      if (this.courseId == "") {
        this.toastr.warning("Please select the programme!");
        return this.deactivateSpinner();
      } else {
        this.courseId = this.courseId || this.userDetails?.get_cluster?.COURSE_ID;
      }
      let passportPhoto: any;
      let certifiedCopies: any;
      const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
      let existingPhoto = this.userDetails?.result.upload_4?.replace(prefixToRemove, '');
      if (this.upload_2 != existingPhoto || this.fileName4 == '') {
        passportPhoto = this.fileName4 || "";
      }
      else {
        passportPhoto = this.userDetails?.result.upload_4;
      }

      let ExistingcertifiedCopies = this.userDetails?.result.upload_1?.replace(prefixToRemove, '');
      if (this.upload_1 != ExistingcertifiedCopies || this.fileName1 == '') {
        certifiedCopies = this.fileName1 || "";
      }
      else {
        certifiedCopies = this.userDetails?.result.upload_1;
      }
      let payload = {
        "ROLE_ID": this.roleId,
        "ACTION_USERID": localStorage.getItem('UserId'),
        // "POSTAL_ADDRESS": value.POSTAL_ADDRESS || this.userDetails?.result.postal_address,
        // "PHYSICAL_ADDRESS": value.PHYSICAL_ADDRESS || this.userDetails?.result.physical_address,
        // "NAME_OF_EMERGENCY": value.NAME_OF_EMERGENCY || this.userDetails?.result.name_of_emergency,
        // "RELATIONSHIPNAME_OF_EMERGENCY": value.RELATIONSHIPNAME_OF_EMERGENCY == null ? 0 : value.RELATIONSHIPNAME_OF_EMERGENCY == 0 ? parseInt(value.RELATIONSHIPNAME_OF_EMERGENCY) : parseInt(value.RELATIONSHIPNAME_OF_EMERGENCY) || this.userDetails?.result?.relationshipname_of_emergency,
        // "MOBILE_NO_EMERGENCY": value.MOBILE_NO_EMERGENCY || this.userDetails?.result.mobile_no_emergency,
        // "SPONSORS_NAME": value.SPONSORS_NAME || this.userDetails?.result.sponsors_name,
        // "SPONSORS_MOBILE": value.SPONSORS_MOBILE || this.userDetails?.result.sponsors_mobile,
        // "NATIONALITY": parseInt(value.NATIONALITY) || this.userDetails?.result.nationality,
        // "NEAREST_TOWN": value.NEAREST_TOWN || this.userDetails?.result.nearest_town,
        "FIRSTNAME": value.FIRSTNAME == '' ? value.FIRSTNAME : value.FIRSTNAME || this.userDetails?.result?.firstname,
        "LASTNAME": value.LASTNAME == '' ? value.LASTNAME : value.LASTNAME || this.userDetails?.result?.lastname,
        // "POSTAL_CODE": value.POSTAL_CODE || this.userDetails?.result?.POSTAL_CODE,
        // "BIRTH_COUNTRY_ID": value.BIRTH_COUNTRY_ID || this.userDetails?.result.birth_country_id || 0,
        // "VERIFICATION_STATUS": 0,
        "REGISTRATION_DETAILS_ID": this.userDetails?.result?.registration_details_id,
        // "SPONSORS_EMAIL": value.SPONSORS_EMAIL || this.userDetails?.result.sponsors_email,
        // "ADDRESS_OF_EMERGENCY": value.ADDRESS_OF_EMERGENCY || this.userDetails?.result.address_of_emergency,
        // "POSTALCODE_OF_EMERGENCY": value.POSTALCODE_OF_EMERGENCY || this.userDetails?.result.postalcode_of_emergency,
        // "TOWNCITY_OF_EMERGENCY": value.TOWNCITY_OF_EMERGENCY || this.userDetails?.result.towncity_of_emergency,
        // "COUNTRYID_OF_EMERGENCY": value.COUNTRYID_OF_EMERGENCY || this.userDetails?.result.countryid_of_emergency,
        // "EMAILID_OF_EMERGENCY": value.EMAILID_OF_EMERGENCY || this.userDetails?.result.emailid_of_emergency,
        "IS_SUBMIT": IS_SUBMIT,
        "MOBILENO_CODE_ID": value.MOBILENO_CODE_ID || this.countryCode || this.userDetails?.result?.mobileno_code_id,
        // "MOBILENO2_CODE_ID": value.MOBILENO2 == "" ? value.MOBILENO2_CODE_ID : (value.MOBILENO2_CODE_ID || this.countryCode) || this.userDetails?.result?.mobileno2_code_id,
        // "EMERGENCY_MOBILENO_CODE_ID": value.EMERGENCY_MOBILENO_CODE_ID == null ? 0 : value.EMERGENCY_MOBILENO_CODE_ID || this.emergencyCountryCode || this.userDetails?.result?.emergency_mobileno_code_id,
        "DOB": selectedDob || this.userDetails?.result?.dob,
        "COUNTIES_ID_OF_EMERGENCY": countryNameOfEmergency == 'Kenya' ? value.COUNTIES_ID_OF_EMERGENCY || this.userDetails?.result?.counties_id_of_emergency : 0,
        "COUNTIES_ID": countyId,
        "ID_PASSPORT_TYPE": value.ID_PASSPORT_TYPE == null ? 0 : value.ID_PASSPORT_TYPE == '0' ? parseInt(value.ID_PASSPORT_TYPE) : parseInt(value.ID_PASSPORT_TYPE) || this.userDetails?.result?.id_passport_type,
        "MIDDLENAME": value.MIDDLENAME || this.userDetails?.result.middlename,
        "COUNTRY_ID": value.COUNTRY_ID || this.userDetails?.result.country_id || 0,
        // "ID_PASSPORT": value.ID_PASSPORT || this.userDetails?.result.id_passport,
        "GENDER": value.GENDER || this.userDetails?.result.gender || 0,
        "MOBILENO": value.MOBILENO || this.userDetails?.result.mobileno,
        // "MARTIALSTATUS": value.MARTIALSTATUS || this.userDetails?.result.martialstatus,
        "PROGRAMME_CATEGORY_ID": value.PROGRAMME_CATEGORY_ID || this.userDetails?.result.programme_category_id,
        // "MOBILENO2": value.MOBILENO2 || this.userDetails?.result?.mobileno2,
        "IS_RPL": value.IS_RPL || false || this.userDetails?.lst[0]?.IS_RPL,
        "COURSEID": parseInt(this.courseId) || value.COURSEID || this.userDetails?.get_cluster?.COURSE_ID,
        // "MODE_OF_STUDY": value.MODE_OF_STUDY || this.userDetails?.result.emailid_of_emergency,
        "UPLOAD_1": certifiedCopies,
        "UPLOAD_3": this.fileName3 || '' || this.userDetails?.lst[0]?.UPLOAD_3,
        "UPLOAD_4": passportPhoto,
        "USER_ID": localStorage.getItem('UserId'),
        "REFERRAL_PLATFORM_ID": (this.selectedReferral === null || this.selectedReferral === '') ? 0 : this.selectedReferral,
        "REFERRAL_NAME": this.REFERRAL_NAME,
        // "SUBCOUNTIES_ID": countyName == 'Kenya' ? value.SUBCOUNTIES_ID || this.userDetails?.result?.subcounties_id : 0,
        // "COUNTIES_CONSTITUENCIES_ID": countyName == 'Kenya' ? value.COUNTIES_CONSTITUENCIES_ID || this.userDetails?.result?.counties_constituencies_id : 0,
        "dlc_Education_Details": this.educationData,
        "dlc_workexperiance_details": this.ApplicantData,
        "dlc_Recommendations": this.RefreeData,
        "TNT_CODE": localStorage.getItem('TenantCode'),
        "CREATEDBY": localStorage.getItem('UserId'),
        "USERNAME": value.USERNAME || this.userDetails?.result?.username,
        "DLC_CLUSTERS": {
          "COURSE_ID": this.courseId || this.userDetails?.get_cluster?.COURSE_ID,
          "APPLICATION_TYPE": value.APPLICATION_TYPE || this.userDetails?.get_cluster?.APPLICATION_TYPE,
          "BASIC_EDUCATION_SYSTEM": 0,
          "MEAN_GRADE": value.MEAN_GRADE || this.userDetails?.get_cluster?.MEAN_GRADE,
          "CLUSTER_SUBJECT1_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT1_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT1_NAME1,
          "CLUSTER_SUBJECT2_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT2_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT2_NAME2,
          "CLUSTER_SUBJECT3_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT3_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT3_NAME3,
          "CLUSTER_SUBJECT4_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT4_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT4_NAME4,
          "CLUSTER_SUBJECT1_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT1_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT1_GRADE1,
          "CLUSTER_SUBJECT2_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT2_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT2_GRADE2,
          "CLUSTER_SUBJECT3_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT3_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT3_GRADE3,
          "CLUSTER_SUBJECT4_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT4_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT4_GRADE4,
          "ENGLISH_PROFICIENCY": value.ENGLISH_PROFICIENCY == '0' ? 0 : value.ENGLISH_PROFICIENCY || this.userDetails?.get_cluster?.ENGLISH_PROFICIENCY,
          "COMPUTING_SKILLS_PROFICIENCY": value.COMPUTING_SKILLS_PROFICIENCY == '0' ? 0 : value.COMPUTING_SKILLS_PROFICIENCY || this.userDetails?.get_cluster?.COMPUTING_SKILLS_PROFICIENCY,
          // "MODE_OF_STUDY": value.MODE_OF_STUDY || 0 || this.userDetails?.get_cluster?.MODE_OF_STUDY,
          "CLUSTER_ID": this.userDetails?.get_cluster == null ? 0 : this.userDetails?.get_cluster?.CLUSTER_ID,
          "BASIC_EDUCATION_SYSTEM_OTHER": '',
          "IS_KUCCPS": false,
          // value.IS_KUCCPS === "true" ? true : value.IS_KUCCPS === "false" ? false : this.userDetails?.get_cluster?.IS_KUCCPS
        }
      }
      // if(payload.DLC_CLUSTERS.IS_KUCCPS ==""){
      //   this.toastr.error("KUCCPS in Program Selection is mandatory");
      //   this.deactivateSpinner();
      //   return
      // }
      // if (payload.ID_PASSPORT == "") {
      //   this.toastr.error("Passport Id in biographical information is mandatory");
      //   this.deactivateSpinner();
      //   return
      // }
      if (payload.DOB == "undefined-undefined-undefined" || month == '00' || day == '00') {
        this.toastr.error("DOB in biographical information is mandatory");
        return this.deactivateSpinner();
      }
      this.CommonService.postCall('Registration/UpdateRegistrationDetails', payload).subscribe((res: any) => {
        if (IS_SUBMIT == 2) {
          document.getElementById('updateClose')?.click();
          $('#updateClose').modal('hide');
          this.getUserDetails(payload.COURSEID, false)
          $('#applicationForm').modal('show');
          this.deactivateSpinner();
        } else {
          setTimeout(() => {
            document.getElementById('updateClose')?.click();
            $('#updateClose').modal('hide');
            this.toastr.success("Application Updated Successful!");
            this.get();
            // this.getUserDetails();
            this.deactivateSpinner();
          }, 500);
        }

      }, (err: any) => {
        this.toastr.error(err.error ? err.error : 'Not Updated');
        this.deactivateSpinner();
      })
    }
  }
  closeApplicationPopup() {
    if (!this.isBooltrue) {
      $('#editPopup').modal('show');
    }
  }
  yearValidate(event: any) {
    if (event === '1') {
      const currentYear = new Date().getFullYear();
      this.years = Array.from({ length: currentYear - 1989 + 1 }, (_, i) => currentYear - i);
      setTimeout(() => {
        this.selectedYearStart = '';
        this.selectedYearEnd = '';
      }, 200);
    } else {
      // Reset the years to the default range (e.g., the last 100 years)
      this.years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
    }
  }
  clearOther(selectedString: any) {
    if (selectedString != 5) {
      this.QUALIFICATION = '';
    }
  }
  mobileValidation(event: any, id: number) {
    if (id == 1) {
      if (event.target.value.charAt(0) === '0') {
        // throw new Error('Phone number cannot start with zero.');
        this.toastr.error("Mobile number cannot start with zero.");
        this.signUpForm.controls['MOBILENO'].setValue('');
      }
    } else if (id == 2) {
      if (event.target.value.charAt(0) === '0') {
        // throw new Error('Phone number cannot start with zero.');
        this.toastr.error("Mobile number cannot start with zero.");
        this.signUpForm.controls['MOBILENO2'].setValue('');
      }
    } else if (id == 3) {
      if (event.target.value.charAt(0) === '0') {
        // throw new Error('Phone number cannot start with zero.');
        this.toastr.error("Mobile number cannot start with zero.");
        this.signUpForm.controls['MOBILE_NO_EMERGENCY'].setValue('');
      }
    }

  }

  onKeyPress(event: any, type: any) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Allow only numbers (0-9) and restrict the length to 12 characters
    const charCode = event.which ? event.which : event.keyCode;
    const isNumber = charCode >= 48 && charCode <= 57;

    if (!isNumber || currentValue.length >= 12) {
      event.preventDefault();
    }

    // Additional logic for country code checks
    if (type == 1 && this.countryCode == '336') {
      if (currentValue.length === 0 && event.key === '0') {
        event.preventDefault(); // Prevent starting with 0
      }
    } else if (type == 2 && this.countryCodeOne == '336') {
      if (currentValue.length === 0 && event.key === '0') {
        event.preventDefault();
      }
    } else if (type == 3 && this.emergencyCountryCode == '336') {
      if (currentValue.length === 0 && event.key === '0') {
        event.preventDefault();
      }
    }
  }

  onPaste(event: ClipboardEvent, type: number) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text');

    // Remove any non-numeric characters from the pasted text
    const sanitizedText = pastedText.replace(/\D/g, '');

    // Get the input element and its current value
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Limit the combined length of the current value and pasted text to 12 characters
    const allowedLength = 12 - currentValue.length;

    // Trim the pasted text if it exceeds the allowed length
    const trimmedText = sanitizedText.substring(0, allowedLength);

    // Prevent the default paste action
    event.preventDefault();

    // Manually set the input value with the sanitized and trimmed text
    const newValue = currentValue.slice(0, inputElement.selectionStart) +
      trimmedText +
      currentValue.slice(inputElement.selectionEnd);

    inputElement.value = newValue;

    // Optionally, update the form control if needed
    if (type === 1) {
      this.signUpForm.controls['MOBILENO'].setValue(newValue);
    } else if (type === 2) {
      this.signUpForm.controls['MOBILENO2'].setValue(newValue);
    } else if (type === 3) {
      this.signUpForm.controls['MOBILE_NO_EMERGENCY'].setValue(newValue);
    }
  }
  close() {
    document.getElementById('md_close').click();
  }

  displayModel() {
    setTimeout(() => (<HTMLInputElement>document.getElementById('btnShowModel')).click(), 10);
  }

  applicationFeeList() {
    this.tableData = [];
    this.CommonService.postCall('Registration/LoadApplicationFeeDetails', { APPL_PAYDET_USER_ID: this.isPaid }).subscribe((res: any) => {
      this.tableData = res;
    })
  }
  pay() {
    let payload = {
      "APPL_PAYDET_USER_ID": localStorage.getItem('UserId'),
      "APPL_PAYDET_ISPAID": 2
    }
    this.CommonService.postCall('Registration/UpdateApplicationFeeStatus', payload).subscribe((res: any) => {
      if (res.message == "true")
        document.getElementById('payment')?.click();
      let payId: any = 2;
      localStorage.setItem('IsPaid', payId)

    })
  }
  changeFile(event: any, id: number) {



    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const maxSizeInBytes = 8 * 1024 * 1024; // 8 MB
      if (file.size > maxSizeInBytes) {
        this.toastr.error('File size exceeds 8 MB limit.');
        return event.target.value = '';

      }
      let name = file.name;
      let filetype = name.split('.').pop();
      if (id == 4) {
        const typesOfFile1: object = {
          'Uploaded Material': {
            types: ['jpg', 'png', 'jpeg'],
            message: 'Please upload the',
          },

        };
        let materialType1: object = typesOfFile1['Uploaded Material'];
        let check = materialType1['types'].includes(filetype.toLowerCase());
        // this.toastr.error("Please upload the jpg, png, jpeg formats only");

        if (check) {
          this.upload4 = file;
          return this.upload(id);

        } else {
          // alert(' Please upload pdf and doc file formats only.')
          this.toastr.warning("Only file formats jpg,png,jpeg allowed");
          return event.target.value = '';

        }
      }
      let materialType: object = this.typesOfFile['Uploaded Material'];
      let check = materialType['types'].includes(filetype.toLowerCase());
      if (check) {
        if (id == 1) {
          this.progressTwo = 0;
          this.upload1 = file;
          this.upload(id); return
        } else if (id == 2) {
          this.upload2 = file;
          this.upload(id); return
        } else if (id == 3) {
          this.progressThree = 0;
          this.upload3 = file;
          this.upload(id); return
        } else if (id == 5) {
          this.upload5 = file;
          this.upload(id); return
        } else if (id == 6) {
          this.progressOne = 0;
          this.upload6 = file;
          this.upload(id); return
        } else if (id == 4) {
          this.progress = 0;
        }

      } else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning("Ensure file format is PDF only");
        event.target.value = '';
      }


    }
  }

  upload(id: number) {
    this.activeSpinner();
    const formData = new FormData();
    switch (id) {
      case 1:
        formData.append('upload_1', this.upload1);
        break;
      case 2:
        formData.append('upload_2', this.upload2);
        break;
      case 3:
        formData.append('upload_3', this.upload3);
        break;
      case 4:
        formData.append('upload_4', this.upload4);
        break;
      case 5:
        formData.append('upload_5', this.upload5);
        break;
      default:
        formData.append('upload_6', this.upload6);
        break;
    }

    formData.append('DIRECTORY_NAME', 'registrationDocuments');
    this.FileuploadService.upload(formData, 'Registration/Upload_File').subscribe(
      (event: any) => {
        setTimeout(() => {
          try {
            if (id == 1) {
              this.isProgressTwo = true;
              this.progressTwo = 0;
              this.fileName1 = event.path;
              const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
              this.upload_1 = this.fileName1?.replace(prefixToRemove, '');
              this.progressTwo = 100;
              return this.deactivateSpinner();

            } else if (id == 2) {
              this.fileName2 = event.path;
              return this.deactivateSpinner();
            } else if (id == 3) {
              this.isProgressThree = true;
              this.progressThree = 0;
              this.fileName3 = event.path;
              const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
              this.upload_3 = this.fileName3?.replace(prefixToRemove, '');
              this.progressThree = 100;
              return this.deactivateSpinner();
            } else if (id == 4) {
              this.isProgress = true;
              this.progress = 0;
              this.fileName4 = event.path;
              const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
              this.upload_2 = this.fileName4?.replace(prefixToRemove, '');
              this.progress = 100;
              return this.deactivateSpinner();
            } else if (id == 6) {
              this.isProgressOne = true;
              this.progressOne = 0;
              this.fileName6 = event.path;
              const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
              this.progressOne = 100;
              this.upload_6 = this.fileName6?.replace(prefixToRemove, '');
              return this.deactivateSpinner();

              // this.upload_2 = this.fileName4;
              // this.deactiveSpinner();
              // setTimeout(() => {
              //   if (res.path != '') {
              //     this.toastr.success('Uploaded!');
              //     this.deactiveSpinner();
              //     return
              //   } else {
              //     this.toastr.warning("upload Failed, Please try again!");
              //     return
              //   }
              // }, 2000);

            } else {
              this.fileName5 = event.path;
              this.deactivateSpinner();
            }
          } catch (e) {
            console.log(e);
            if (id == 4) {
              this.progress = 0;
            } else if (id == 6) {
              this.progressOne = 0;
            } else if (id == 1) {
              this.progressTwo = 0;
            } else if (id == 3) {
              this.progressThree = 0;
            }
            this.deactivateSpinner();
          }
        }, 10000);
      },
      (err) => {
        if (id == 4) {
          this.progress = 0;
        } else if (id == 6) {
          this.progressOne = 0;
        } else if (id == 1) {
          this.progressTwo = 0;
        } else if (id == 3) {
          this.progressThree = 0;
        }
        this.deactivateSpinner();
      }
    );
  }
  removeFile(id: any) {
    if (id == '1') {
      this.upload_1 = "";
      this.fileInputRefupload1.nativeElement.value = '';
      this.fileName1 = '';
      this.progressTwo = 0;
      this.isProgressTwo = false;
    } else if (id == '2') {
      this.upload_3 = "";
      this.fileInputRef.nativeElement.value = ''
      this.fileName2 = '';
      this.progressThree = 0;
      this.isProgressThree = false;
    } else if (id == '4') {
      this.upload_6 = "";
      this.fileInputRefupload2.nativeElement.value = '';
      this.userDetails.result.identity_document_upload = '';
      this.fileName6 = '';
      this.isProgressOne = false;
      this.progressOne = 0;
    }
    else {
      this.upload_2 = "";
      this.fileInputRefPassport.nativeElement.value = '';
      this.fileName4 = '';
      this.progress = 0;
      this.isProgress = false;
    }

  }

  validateEmail(event: any, targetField: string) {
    let val = event.target.value;
    let exp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (targetField === 'sponserEmail') {
      if (new RegExp(exp).test(val)) {
        // Check if there are numbers after "@" symbol
        let parts = val.split('@');
        if (parts.length === 2 && /\d/.test(parts[1])) {
          this.EMAIL_ADDRESS = null;
          this.toastr.warning('Numbers are not allowed after "@"');
        } else {
          this.EMAIL_ADDRESS = val;
        }
      } else {
        this.EMAIL_ADDRESS = null;
        this.toastr.warning('Invalid email credentials, please enter a valid email');
      }
    } else if (targetField === 'Email') {
      if (new RegExp(exp).test(val)) {
        // Check if there are numbers after "@" symbol
        let parts = val.split('@');
        if (parts.length === 2 && /\d/.test(parts[1])) {
          this.Emailid = null;
          this.toastr.warning('Numbers are not allowed after "@"');
        } else {
          this.Emailid = val;
        }
      } else {
        this.Emailid = null;
        this.toastr.warning('Invalid email credentials, please enter a valid email');
      }
    }
  }
  getUserDetails(item: any, getBoolValue: boolean) {
    this.COURSEID = item.course_id || item;
    this.isBooltrue = getBoolValue;
    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": localStorage.getItem('UserId'), "COURSE_ID": item.course_id || item }).subscribe((res: any) => {
      this.userDetails = res;
      if (this.userDetails?.get_cluster?.APPLICATION_TYPE == 1) {
        this.clusters = true;
        // this.loadClusterDetails(this.userDetails.get_cluster);
      }
    })

  }

  ConvertToPDF(item: any) {
    if (item == 'application') {
      let payload = {
        "USERID": localStorage.getItem('UserId'),
        "file_name": "ApplicationFormDetails.jrxml",
        "file_type": "pdf",
        "token": localStorage.getItem('tkn'),
        "COURSE_ID": this.COURSEID

      };
      this.activeSpinner();
      this.CommonService.reportAPi(payload)
        .subscribe((res: any) => {
          const urlSegments = res.data.viewPath.split('/');
          const fileName = urlSegments[urlSegments.length - 1];
          const a = document.createElement('a');
          a.href = environment.downloadReportUrl + fileName;
          a.download = 'Application Form.pdf'; // Specify the desired file name
          a.style.display = 'none'; // Hide the anchor element
          document.body.appendChild(a); // Append to the DOM
          a.click(); // Trigger the download
          document.body.removeChild(a);
          document.getElementById('Closemodal')?.click();
          this.deactivateSpinner();

        }, (err: any) => {
          this.deactivateSpinner();
        });
      this.deactivateSpinner();

      // const data = document.getElementById('printtable');
      // html2canvas(data).then((canvas: any) => {
      //   const imgWidth = 208;
      //   const pageHeight = 295;
      //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //   let heightLeft = imgHeight;
      //   let position = 0;
      //   heightLeft -= pageHeight;
      //   const doc :any= new jspdf('p', 'mm');
      //   doc.text('', 10, 10); // Add the header.
      //   doc.text('', 10, doc.internal.pageSize.height - 10); // Add the footer.

      //   doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      //   while (heightLeft >= 0) {
      //     position = heightLeft - imgHeight;
      //     doc.addPage();
      //     doc.text('', 10, 10); // Add the header.
      //     doc.text('', 10, doc.internal.pageSize.height - 10); // Add the footer.
      //     doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      //     heightLeft -= pageHeight;
      //   }
      //   doc.save('ApplicationLetter.pdf');
      // });

      // const element = document.getElementById('printtable');
      // html2canvas(element, {
      //   scrollX: 0,
      //   scrollY: -window.scrollY,
      //   windowWidth: document.documentElement.scrollWidth,
      //   windowHeight: document.documentElement.scrollHeight,
      // }).then(function (canvas) {
      //   const pdf = new jspdf('p', 'mm', 'a4');
      //   const imgData = canvas.toDataURL('image/png');
      //   const imgWidth = 210; // A4 size: 210mm
      //   const pageHeight = (canvas.height * imgWidth) / canvas.width;
      //   let position = 0;

      //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, pageHeight);
      //   position -= 297; // Height of A4 page in mm

      //   while (position >= -canvas.height) {
      //     pdf.addPage();
      //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, pageHeight);
      //     position -= 297;
      //   }

      //   pdf.save('generated.pdf');
      //   document.getElementById('Closemodal')?.click();
      // });
    } else {
      let payload = {
        "USERID": localStorage.getItem('UserId'),
        "file_name": "AdmissionLetter.jrxml",
        "file_type": "pdf",
        "token": localStorage.getItem('tkn'),
        "COURSE_ID": this.COURSEID

      };
      this.activeSpinner();
      this.CommonService.reportAPi(payload)
        .subscribe((res: any) => {
          const urlSegments = res.data.viewPath.split('/');
          const fileName = urlSegments[urlSegments.length - 1];
          const a = document.createElement('a');
          a.href = environment.downloadReportUrl + fileName;
          a.download = 'Admission Letter.pdf'; // Specify the desired file name
          a.style.display = 'none'; // Hide the anchor element
          document.body.appendChild(a); // Append to the DOM
          a.click(); // Trigger the download
          document.body.removeChild(a);
          document.getElementById('admsionForm')?.click();
          this.deactivateSpinner();

        }, (err: any) => {
          this.deactivateSpinner();
        });
      this.deactivateSpinner();

      // const data = document.getElementById('admissionLetter');
      // html2canvas(data).then((canvas: any) => {
      //   const imgWidth = 208;
      //   const pageHeight = 295;
      //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //   let heightLeft = imgHeight;
      //   let position = 0;
      //   heightLeft -= pageHeight;
      //   const doc :any= new jspdf('p', 'mm');
      //   doc.text('', 10, 10); // Add the header.
      //   doc.text('', 10, doc.internal.pageSize.height - 10); // Add the footer.

      //   doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      //   while (heightLeft >= 0) {
      //     position = heightLeft - imgHeight;
      //     doc.addPage();
      //     doc.text('', 10, 10); // Add the header.
      //     doc.text('', 10, doc.internal.pageSize.height - 10); // Add the footer.
      //     doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      //     heightLeft -= pageHeight;
      //   }
      //   doc.save('AddmissionForm.pdf');
      //   document.getElementById('admsionForm')?.click();
      // });

      // const element = document.getElementById('admissionLetter');
      // html2canvas(element, {
      //   scrollX: 0,
      //   scrollY: -window.scrollY,
      //   windowWidth: document.documentElement.scrollWidth,
      //   windowHeight: document.documentElement.scrollHeight,
      // }).then(function (canvas) {
      //   const pdf = new jspdf('p', 'mm', 'a4');
      //   const imgData = canvas.toDataURL('image/png');
      //   const imgWidth = 210; // A4 size: 210mm
      //   const pageHeight = (canvas.height * imgWidth) / canvas.width;
      //   let position = 0;

      //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, pageHeight);
      //   position -= 297; // Height of A4 page in mm

      //   while (position >= -canvas.height) {
      //     pdf.addPage();
      //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, pageHeight);
      //     position -= 297;
      //   }

      //   pdf.save('generated.pdf');
      //   document.getElementById('admsionForm')?.click();

      // });
    }



  }

  downloadForm(item: string) {
    // this.CommonService.activateSpinner();
    // if (item == 'application') {
    this.ConvertToPDF(item);
    // const data = document.getElementById('printtable');
    // html2canvas(data).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const applicationFormWidth = canvas.width;
    //   const applicationFormHeight = canvas.height;
    //   const pdf = new jspdf('p', 'mm', [applicationFormWidth, applicationFormHeight]);
    //   pdf.addImage(imgData, 'PNG', 0, 0, applicationFormWidth, applicationFormHeight);
    //   pdf.save('ApplicationForm.pdf');
    //   this.deactivateSpinner();
    // });
    // } else {
    //   var data: any = document.getElementById('admissionLetter');
    //   html2canvas(data).then((canvas: any) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jspdf('p', 'mm', [297, 210]);
    //     const imgProps = pdf.getImageProperties(imgData);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     const contentDataURL = canvas.toDataURL('image/png')
    //     var position = 0;
    //     pdf.addImage(contentDataURL, 'PNG', 0, 0, pdfWidth, pdfHeight)
    //     pdf.save('AdmissionLetter.pdf'); // Generated PDF
    //     this.CommonService.deactivateSpinner();
    //     setTimeout(() => {
    //       document.getElementById('admsionForm')?.click();
    //       // this.closeModal.emit(true);
    //     }, 2000);
    //   });
    // }


  }
  validateGrade(event: any) {
    const inputValue = event.target.value;
    const gradePattern = /^[A-F][\+\-]?$/;

    if (!gradePattern.test(inputValue)) {

      this.signUpForm.get('MEAN_GRADE').setErrors({ invalidGrade: true });
      this.toastr.warning("Please enter Valid Grade");
      this.signUpForm.get('MEAN_GRADE').setValue('');
      // this.grade = '';
    } else {
      // Valid grade notation
      this.signUpForm.get('MEAN_GRADE').setErrors(null);
    }
  }


  openAdmissionLtr() {
    $("#admissionForm").modal("show");
    $('#admissionForm').appendTo("body");
  }
  showComments(item: any) {
    $("#commentsModel").modal("show");
    this.userComments = item.hold_rej_comments;
  }
  gotoregform(obj: any) {
    let programId = obj.course_id;
    // localStorage.setItem('UserBaseSidebarMenu', 'true');
    if (!this.isFirstLogin) {
      this.route.navigate(['/HOME/components/application'], { queryParams: { cId: programId } });
    } else {
      this.route.navigate(['HOME/components/regForm']);
    }
    // , reload: 'reg'
  }
  radioChanged(value: boolean) {
    this.showDropdown = value;
    this.isDisabilityStatus = value;
    if (value == false) {
      this.showDropdown = false;
      this.showReasonTextbox = false;
      this.signUpForm.get('type_of_disability').setValue('');
      this.signUpForm.get('disability_description').setValue('');
    }
  }
  savePersonalDetails(form: FormGroup) {
    this.activeSpinner();
    let value = form.value;
    let countyName = this.country.filter((e: any) => e.COUNTRY_ID == value?.COUNTRY_ID || e.COUNTRY_ID == this.countryId)[0]?.COUNTRY_NAME;
    let countyId: any;
    if (countyName === 'Kenya') {
      countyId = value.COUNTIES_ID;

      if ((countyId == "" || countyId == 0 || countyId == null)) {
        this.toastr.warning("County is mandatory!");
        return this.deactivateSpinner()
      }
    } else {
      countyId = 0;
    }
    let payload1 = {
      "FIRSTNAME": value?.FIRSTNAME?.trim(),
      "LASTNAME": value?.LASTNAME?.trim(),
      "MIDDLENAME": value.MIDDLENAME == '' ? value.MIDDLENAME : value?.MIDDLENAME?.trim(),
      "COUNTRY_ID": value?.COUNTRY_ID,
      "USERID": this.userId,
      "MOBILENO": value?.MOBILENO,
      "COUNTIES_ID": countyId,
      "SUBCOUNTIES_ID": value.SUBCOUNTIES_ID || 0,
      "COUNTIES_CONSTITUENCIES_ID": value.COUNTIES_CONSTITUENCIES_ID || 0,
      "MOBILENO_CODE_ID": value?.MOBILENO_CODE_ID,
      "TNT_CODE": this.TenantCode,
      // "ENGLISH_PROFICIENCY": value.ENGLISH_PROFICIENCY == '0' ? 0 : value.ENGLISH_PROFICIENCY || this.userDetails?.get_cluster?.ENGLISH_PROFICIENCY,
      // "COMPUTING_SKILLS_PROFICIENCY": value.COMPUTING_SKILLS_PROFICIENCY == '0' ? 0 : value.COMPUTING_SKILLS_PROFICIENCY || this.userDetails?.get_cluster?.COMPUTING_SKILLS_PROFICIENCY,
    }
    this.CommonService.postCall('Registration/PersonalDetailsSaveOrUpdate', payload1).subscribe((res: any) => {
      this.toastr.success(res.message);
      if (res.message === 'Personal Details Saved Successfully') {
        // this.nextTab();
        this.personalDetailsSaved = true;
        this.personalDetailsActive = false;
        this.disableProgramDetailsActive = true;
        this.saveBiographicalData(value);
        this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }

    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveBiographicalData(values: any) {
    this.activeSpinner();
    let value = values;
    const month = this.selectedMonth?.toString().padStart(2, '0');
    const day = this.selectedDay?.toString().padStart(2, '0');
    let selectedDob = `${this.selectedYear}-${month}-${day}`;
    if (this.showDropdown == null) {
      this.toastr.warning("Please select the disability in biographical tab");
      return this.deactivateSpinner();
    }
    let payload2 = {
      "USERID": this.userId,
      "GENDER": parseInt(value.GENDER),
      "DOB": selectedDob,
      "COURSE_ID": this.courseId || localStorage.getItem('cID'),
      "is_disability": this.showDropdown,
      "type_of_disability": this.showDropdown == true ? value.type_of_disability : "0",
      "disability_description": value.disability_description,
    }
    if (payload2.DOB === 'undefined-undefined-undefined' || month == '00' || day == '00' || payload2.DOB === '-NaN-NaN' || this.selectedYear == "null" || this.selectedYear == '' || this.selectedYear == null) {
      this.toastr.warning("DOB is mandatory!");
      return this.deactivateSpinner();
    }
    if (payload2.is_disability == true && (payload2.type_of_disability == "" || payload2.type_of_disability == undefined)) {
      this.toastr.warning("Type of disability is mandatory");
      return this.deactivateSpinner();
    }
    this.CommonService.postCall('Registration/BioGraphicalInformationSaveOrUpdate', payload2).subscribe((res: any) => {
      // this.toastr.success(res.message);
      if (res.message === 'Biographical Information Saved Successfully') {
        this.nextTab();
        this.biographicalInfoSaved = true;
        this.disablePersonalDetails = true;
        this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }

    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveNextofkinData(form: FormGroup) {
    this.activeSpinner();
    let value = form.value;
    let countryNameOfEmergency = this.country.find((e: any) => e.COUNTRY_ID == value?.COUNTRYID_OF_EMERGENCY)?.COUNTRY_NAME;

    let payload3 = {
      "USERID": this.userId,
      "NAME_OF_EMERGENCY": value?.NAME_OF_EMERGENCY,
      "RELATIONSHIPNAME_OF_EMERGENCY": parseInt(value?.RELATIONSHIPNAME_OF_EMERGENCY),
      "MOBILE_NO_EMERGENCY": value?.MOBILE_NO_EMERGENCY,
      "ADDRESS_OF_EMERGENCY": value?.ADDRESS_OF_EMERGENCY,
      "POSTALCODE_OF_EMERGENCY": value?.POSTALCODE_OF_EMERGENCY,
      "COUNTRYID_OF_EMERGENCY": parseInt(value?.COUNTRYID_OF_EMERGENCY),
      "COUNTIES_ID_OF_EMERGENCY": countryNameOfEmergency == 'Kenya' ? value?.COUNTIES_ID_OF_EMERGENCY == '' ? 0 : value?.COUNTIES_ID_OF_EMERGENCY : 0,
      "TOWNCITY_OF_EMERGENCY": value?.TOWNCITY_OF_EMERGENCY,
      "EMAILID_OF_EMERGENCY": value?.EMAILID_OF_EMERGENCY,
      "EMERGENCY_MOBILENO_CODE_ID": value?.EMERGENCY_MOBILENO_CODE_ID,
    }
    if (value?.NAME_OF_EMERGENCY == '') {
      this.toastr.warning("Name in next of kin is mandatory!");
      return this.deactivateSpinner()
    } else if (value?.RELATIONSHIPNAME_OF_EMERGENCY == '' || value?.RELATIONSHIPNAME_OF_EMERGENCY == 0 || value?.RELATIONSHIPNAME_OF_EMERGENCY == undefined || value?.RELATIONSHIPNAME_OF_EMERGENCY == null) {
      this.toastr.warning("Relationship in next of kin is mandatory!");
      return this.deactivateSpinner()
    }
    else if (value?.COUNTRYID_OF_EMERGENCY == '' || value?.COUNTRYID_OF_EMERGENCY == 0) {
      this.toastr.warning("Country in next of kin is mandatory!");
      return this.deactivateSpinner()
    }
    this.CommonService.postCall('Registration/EmergencyContactSaveOrUpdate', payload3).subscribe((res: any) => {
      this.toastr.success(res.message);
      if (res.message === 'Next of Kin/ Emergency Contact Saved Successfully') {
        this.nextTab();
        this.nextOfKin = true;
        this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }

    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveEducationData(form: FormGroup) {
    let value = form.value;
    this.activeSpinner();
    if (this.educationData.length == 0) {
      this.toastr.error("Please enter mandatory fields in education background");
      return this.deactivateSpinner();
    }
    if ((value.IS_RPL == "null" || value.IS_RPL == null) && (value.APPLICATION_TYPE == '1' || value.APPLICATION_TYPE == 1 || this.userDetails?.get_cluster?.APPLICATION_TYPE == 1)) {
      this.toastr.warning("IS RPL is mandatory!");
      return this.deactivateSpinner();
    }
    let payload = {
      "IS_RPL": value.IS_RPL || false,
      "UPLOAD_3": this.fileName3 == '' ? '' : (this.userDetails?.lst[0]?.UPLOAD_3 == '' || this.userDetails?.lst[0]?.UPLOAD_3 == null) ? this.fileName3 : this.userDetails?.lst[0]?.UPLOAD_3,
      "dlc_Education_Details": this.educationData
    }
    this.CommonService.postCall('Registration/EducationBackgroundSaveorUpdate', payload).subscribe((res: any) => {
      this.toastr.success(res.message);
      if (res.message === 'Education Background Saved Successfully') {
        this.educationDetails = true;
        this.disablePersonalDetailsTab();
        this.nextTab();
        this.deactivateSpinner();
      }
    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveWorkExpData() {
    this.activeSpinner();
    this.CommonService.postCall('Registration/WorkExperianceSaveorUpdate', this.ApplicantData).subscribe((res: any) => {
      this.toastr.success(res.message);
      if (res.message === 'Work Experience Saved Successfully') {
        this.nextTab();
        this.workExp = true;
        this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }
    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveRecommondationData() {
    this.activeSpinner();
    this.CommonService.postCall('Registration/RecommendationsSaveorUpdate', this.RefreeData).subscribe((res: any) => {
      // this.toastr.success(res.message);
      if (res.message === 'Recommendations Saved Successfully') {
        this.nextTab();
        this.recomondations = true;
        // this.Referrals = true;
        this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }
    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveReferral() {
    this.activeSpinner();
    let payload = {
      "REFERRAL_PLATFORM_ID": (this.selectedReferral === null || this.selectedReferral === '') ? 0 : this.selectedReferral,
      "REFERRAL_NAME": this.REFERRAL_NAME,
      "USERID": this.userId
    }
    this.CommonService.postCall('Registration/ReferralsSaveorUpdate', payload).subscribe((res: any) => {
      // this.toastr.success(res.message);
      if (res.message === 'Referral Saved Successfully') {
        this.nextTab();
        this.Referrals = true;
        this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }
    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : "Failed to save the data");
      this.deactivateSpinner();
    })
  }
  saveProgramData(form: FormGroup) {
    this.activeSpinner();
    let value = form.value;
    let payload = {
      "USER_ID": localStorage.getItem('UserId'),
      "COURSE_ID": this.courseId == '' ? 0 : +this.courseId,
      "APPLICATION_TYPE": value.APPLICATION_TYPE || 0 || this.userDetails?.get_cluster?.APPLICATION_TYPE,
      "BASIC_EDUCATION_SYSTEM": 0,
      "MEAN_GRADE": value.MEAN_GRADE || this.userDetails?.get_cluster?.MEAN_GRADE,
      "CLUSTER_SUBJECT1_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT1_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT1_NAME1,
      "CLUSTER_SUBJECT2_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT2_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT2_NAME2,
      "CLUSTER_SUBJECT3_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT3_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT3_NAME3,
      "CLUSTER_SUBJECT4_NAME": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT4_NAME) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT4_NAME4,
      "CLUSTER_SUBJECT1_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT1_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT1_GRADE1,
      "CLUSTER_SUBJECT2_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT2_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT2_GRADE2,
      "CLUSTER_SUBJECT3_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT3_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT3_GRADE3,
      "CLUSTER_SUBJECT4_GRADE": value.APPLICATION_TYPE == '2' ? 0 : parseInt(value.CLUSTER_SUBJECT4_GRADE) || this.userDetails?.get_cluster?.CLUSTER_SUBJECT4_GRADE4,
      "CLUSTER_ID": this.userDetails?.get_cluster == null ? 0 : this.userDetails?.get_cluster?.CLUSTER_ID,
      "BASIC_EDUCATION_SYSTEM_OTHER": '',
      "ENGLISH_PROFICIENCY": value.ENGLISH_PROFICIENCY == '0' ? 0 : value.ENGLISH_PROFICIENCY || this.userDetails?.get_cluster?.ENGLISH_PROFICIENCY,
      "COMPUTING_SKILLS_PROFICIENCY": value.COMPUTING_SKILLS_PROFICIENCY == '0' ? 0 : value.COMPUTING_SKILLS_PROFICIENCY || this.userDetails?.get_cluster?.COMPUTING_SKILLS_PROFICIENCY,
      // "IS_KUCCPS": false,
      // "IS_RPL": value.IS_RPL || false,
      // "UPLOAD_3": this.fileName3 == '' ? '' : (this.userDetails?.result.upload_3 == '' || this.userDetails?.result.upload_3 == null) ? this.fileName3 : this.userDetails?.result.upload_3,

      "TNT_CODE": localStorage.getItem('TenantCode'),
      "CREATED_BY": localStorage.getItem('UserId'),
      "MODIFIED_BY": localStorage.getItem('UserId'),
    }
    if (payload.APPLICATION_TYPE == '0' || payload.APPLICATION_TYPE == undefined || payload.APPLICATION_TYPE == 0) {
      this.toastr.warning("Application Type is mandatory!");
      return this.deactivateSpinner();
    }
    this.CommonService.postCall('Registration/ProgramSelectionSaveorUpdate', payload).subscribe((res: any) => {
      this.toastr.success(res.message);
      if (res.message === 'Program Selection Saved Successfully') {
        this.nextTab();
        this.applicationProgram = true;
        this.programDetailsActive = false;
        // this.disablePersonalDetailsTab();
        this.deactivateSpinner();
      }
    }, (err: any) => {
      this.toastr.error(err.error ? err.error.message : 'Failed to save the data');
      this.deactivateSpinner();
    })
  }
  add() {
    // console.log(this.FROMPERIOD_YEAR,this.TOPERIOD_YEAR);

    // || !this.FROMPERIOD_YEAR || !this.TOPERIOD_YEARthis.selectedYearStart,this.selectedYearEnd
    if (this.selectedYearStart == "" || this.selectedYearEnd == "") {
      return this.toastr.error("Please enter the details");
    }
    if (!this.INSTITUTION_NAME?.trim() || !this.LEVEL_OF_STUDY?.trim() || !this.QUALIFICATION_ID || !this.EXAM_REG_NO.trim() || this.upload_1 == '' || this.upload_1 == undefined || this.MEAN_GRADE == '') {
      return this.toastr.error("Please enter all mandatory fields");

    }
    const isValidStartDate = this.selectedYearStart;
    const isValidEndDate = this.selectedYearEnd;

    const month = this.selectedMonthStart?.toString().padStart(2, '0');
    const day = this.selectedDayStart?.toString().padStart(2, '0');
    const month1 = this.selectedMonthEnd?.toString().padStart(2, '0');
    const day1 = this.selectedDayEnd?.toString().padStart(2, '0');
    // this.FROMPERIOD_YEAR = `${isValidStartDate}-${month}-${day}`;
    // this.TOPERIOD_YEAR = `${isValidEndDate}-${month1}-${day1}`;
    this.FROMPERIOD_YEAR = `${isValidStartDate}`;
    this.TOPERIOD_YEAR = `${isValidEndDate}`;
    // const partsOfStartDate = this.FROMPERIOD_YEAR?.split('-');
    // const partsOfEndDate = this.TOPERIOD_YEAR?.split('-');\
    const partsOfStartDate = this.FROMPERIOD_YEAR;
    const partsOfEndDate = this.TOPERIOD_YEAR;
    // if ((partsOfStartDate?.length !== 3 || partsOfStartDate.includes('undefined') || this.FROMPERIOD_YEAR == "-00-00"
    //   || partsOfStartDate.includes('NaN') || partsOfStartDate.includes('00') || partsOfStartDate.includes(''))
    //   || (partsOfEndDate?.length !== 3 || partsOfEndDate.includes('undefined') || this.TOPERIOD_YEAR == "-00-00"
    //     || partsOfEndDate.includes('NaN') || partsOfEndDate.includes('00') || partsOfEndDate.includes(''))) {
    //   this.toastr.error("Invalid Dates");
    //   setTimeout(() => { this.educationDates(); }, 100);

    //   return// Date is incomplete or contains "undefined" parts
    // }

    // this.QUALIFICATION_ID = '';
    if (this.editedItemIndex !== -1) {
      // Editing an existing education item

      this.educationData[this.editedItemIndex] = {
        INSTITUTION_NAME: this.INSTITUTION_NAME,
        USER_ID: this.userId,
        EDUCATION_COUNTRY_ID: this.EDUCATION_COUNTRY_ID || 0,
        QUALIFICATION: this.QUALIFICATION,
        QUALIFICATION_ID: this.QUALIFICATION_ID,
        EXAM_REG_NO: this.EXAM_REG_NO,
        LEVEL_OF_STUDY: this.LEVEL_OF_STUDY,
        PERCENTAGE: 0,
        FROMPERIOD_YEAR: this.FROMPERIOD_YEAR,
        TOPERIOD_YEAR: this.TOPERIOD_YEAR,
        ADDITIONAL_INFORMATION: this.ADDITIONAL_INFORMATION,
        EDU_DETAIL_CREATEDBY: this.userId,
        EDU_DETAIL_MODIFIEDBY: this.userId,
        EDU_DETAIL_TNT_CODE: this.TenantCode,
        UPLOAD_1: this.fileName1 || this.upload_1,
        MEAN_GRADE: this.MEAN_GRADE,
        EDU_DETAIL_ID: this.EDU_DETAIL_ID, // Update EDU_DETAIL_ID,
        GRADE: this.grades.find((e: any) => e.AMT_ID == this.MEAN_GRADE)?.DESCRIPTION,
        COURSE_ID: this.courseId,
      };

    } else if (this.educationData.length < 4) {
      // Adding a new education item
      // const uniqueId = Math.floor(Math.random() * 100).toString().padStart(2, '0');
      const uniqueId = this.nextEducationId++;
      this.educationData.push({
        INSTITUTION_NAME: this.INSTITUTION_NAME,
        USER_ID: this.userId,
        EDUCATION_COUNTRY_ID: this.EDUCATION_COUNTRY_ID || 0,
        QUALIFICATION: this.QUALIFICATION,
        QUALIFICATION_ID: this.QUALIFICATION_ID,
        EXAM_REG_NO: this.EXAM_REG_NO,
        LEVEL_OF_STUDY: this.LEVEL_OF_STUDY,
        PERCENTAGE: 0,
        FROMPERIOD_YEAR: this.FROMPERIOD_YEAR,
        TOPERIOD_YEAR: this.TOPERIOD_YEAR,
        ADDITIONAL_INFORMATION: this.ADDITIONAL_INFORMATION,
        EDU_DETAIL_CREATEDBY: this.userId,
        EDU_DETAIL_MODIFIEDBY: this.userId,
        EDU_DETAIL_TNT_CODE: this.TenantCode,
        UPLOAD_1: this.fileName1 || this.upload_1,
        MEAN_GRADE: this.MEAN_GRADE,
        EDU_DETAIL_ID: uniqueId,
        GRADE: this.grades.find((e: any) => e.AMT_ID == this.MEAN_GRADE)?.DESCRIPTION,
        COURSE_ID: this.courseId,
        // educationId: parseInt(uniqueId)
      });

    }

    // Reset the editedItemIndex
    this.editedItemIndex = -1;
    this.INSTITUTION_NAME = ''; this.EDUCATION_COUNTRY_ID = '';
    this.QUALIFICATION = ''; this.EXAM_REG_NO = ''; this.LEVEL_OF_STUDY = '';
    this.QUALIFICATION_ID = ''; this.fileName1 = ''; this.MEAN_GRADE = ''; this.UPLOAD_1 = ''; this.upload_1 = '';
    this.FROMPERIOD_YEAR = ''; this.TOPERIOD_YEAR = ''; this.ADDITIONAL_INFORMATION = '';
    this.isProgressTwo = false;
    this.progressTwo = 0;
    setTimeout(() => { this.educationDates(); }, 100);
    if (this.userDetails?.lst.length != 0) {
      this.updateEDUDetailIdsFromApiResponse();
    }
  }
  updateEDUDetailIdsFromApiResponse() {
    this.educationData.forEach((apiItem) => {
      const matchingItemIndex = this.educationData.findIndex((eduItem) => eduItem.EDU_DETAIL_ID === apiItem.EDU_DETAIL_ID);

      if (matchingItemIndex !== -1) {
        this.educationData[matchingItemIndex].EDU_DETAIL_ID = apiItem.EDU_DETAIL_ID;
        this.educationData[matchingItemIndex].INSTITUTION_NAME = apiItem.INSTITUTION_NAME;
        this.educationData[matchingItemIndex].QUALIFICATION = apiItem.QUALIFICATION;
        this.educationData[matchingItemIndex].EDUCATION_COUNTRY_ID = apiItem.EDUCATION_COUNTRY_ID;
        this.educationData[matchingItemIndex].QUALIFICATION_ID = apiItem.QUALIFICATION_ID;
        this.educationData[matchingItemIndex].EXAM_REG_NO = apiItem.EXAM_REG_NO;
        this.educationData[matchingItemIndex].LEVEL_OF_STUDY = apiItem.LEVEL_OF_STUDY;
        this.educationData[matchingItemIndex].FROMPERIOD_YEAR = apiItem.FROMPERIOD_YEAR;
        this.educationData[matchingItemIndex].TOPERIOD_YEAR = apiItem.TOPERIOD_YEAR;
        this.educationData[matchingItemIndex].MEAN_GRADE = apiItem.MEAN_GRADE;
        this.educationData[matchingItemIndex].UPLOAD_1 = apiItem.UPLOAD_1;
        this.editedItemIndex[matchingItemIndex].COURSE_ID = this.courseId;


      }
    });
  }
  remove(index: number) {
    if (index >= 0 && index < this.educationData.length) {
      this.educationData.splice(index, 1);
      this.INSTITUTION_NAME = ''; this.EDUCATION_COUNTRY_ID = '';
      this.QUALIFICATION = ''; this.EXAM_REG_NO = ''; this.LEVEL_OF_STUDY = '';
      this.QUALIFICATION_ID = '';
      this.FROMPERIOD_YEAR = ''; this.TOPERIOD_YEAR = ''; this.ADDITIONAL_INFORMATION = '';
      setTimeout(() => { this.educationDates(); }, 100);

    }
  }
  extractYear(dateString: string): string {
    // Convert dateString to a Date object
    const date = new Date(dateString);
    // Extract the year portion
    return date.getFullYear().toString();
  }
  editdata(row: any, item: any) {
    if (item === 'edu') {
      const date = new Date(row.FROMPERIOD_YEAR);
      const endDate = new Date(row.TOPERIOD_YEAR);
      if (row.QUALIFICATION_ID == '1') {
        this.yearValidate('1')
      }
      this.INSTITUTION_NAME = row.INSTITUTION_NAME;
      this.EDUCATION_COUNTRY_ID = row.EDUCATION_COUNTRY_ID;
      this.LEVEL_OF_STUDY = row.LEVEL_OF_STUDY;
      this.FROMPERIOD_YEAR = this.extractYear(row.FROMPERIOD_YEAR);
      this.TOPERIOD_YEAR = this.extractYear(row.TOPERIOD_YEAR);
      this.QUALIFICATION = row.QUALIFICATION;
      this.QUALIFICATION_ID = row.QUALIFICATION_ID;
      this.EXAM_REG_NO = row.EXAM_REG_NO;
      this.EDU_DETAIL_ID = row.EDU_DETAIL_ID;
      this.MEAN_GRADE = row.MEAN_GRADE;
      this.upload_1 = row.UPLOAD_1;
      setTimeout(() => {
        this.selectedYearStart = date.getFullYear();
        this.selectedMonthStart = date.getMonth() + 1;
        this.startDays = this.generateDaysList(this.selectedYearStart, this.selectedMonthStart);
        this.selectedDayStart = date.getDate();
        this.selectedYearEnd = endDate.getFullYear();
        this.selectedMonthEnd = endDate.getMonth() + 1;
        this.days = this.generateDaysList(this.selectedYearEnd, this.selectedMonthEnd);
        this.selectedDayEnd = endDate.getDate();
      }, 500);
      this.editedItemIndex = this.educationData.findIndex((item) => item.educationId === row.educationId);
    } else if (item == 'wrkExp') {
      const date = new Date(row.WORKEXP_DETAIL_FROM_YEAR);
      const endDate = new Date(row.WORKEXP_DETAIL_TO_YEAR);
      this.WORKEXP_DETAIL_EMPLOYER = row.WORKEXP_DETAIL_EMPLOYER;
      this.WORKEXP_DETAIL_DESIGNATION = row.WORKEXP_DETAIL_DESIGNATION;
      this.WORKEXP_DETAIL_FROM_YEAR = row.WORKEXP_DETAIL_FROM_YEAR;
      this.WORKEXP_DETAIL_TO_YEAR = row.WORKEXP_DETAIL_TO_YEAR;
      this.selectedYearStartWrk = date.getFullYear();
      this.selectedMonthStartWrk = date.getMonth() + 1;
      this.wrkStartDays = this.generateDaysList(this.selectedYearStartWrk, this.selectedMonthStartWrk);
      this.selectedDayStartWrk = date.getDate();
      this.selectedYearEndWrk = endDate.getFullYear();
      this.selectedMonthEndWrk = endDate.getMonth() + 1;
      this.wrkEndDays = this.generateDaysList(this.selectedYearEndWrk, this.selectedMonthEndWrk);
      this.selectedDayEndWrk = endDate.getDate();
      this.wrlExperience = this.ApplicantData.findIndex((item) => item.workId === row.workId);
    } else {
      this.NAME = row.NAME;
      this.ORGANIZATION = row.ORGANIZATION;
      this.MOBILE_NUMBER = row.MOBILE_NUMBER;
      this.CITY_TOWN = row.CITY_TOWN;
      this.NATIONALITY = row.NATIONALITY;
      this.EMAIL_ADDRESS = row.EMAIL_ADDRESS;
      this.recommondation = this.RefreeData.findIndex((item) => item.recomondationId === row.recomondationId);
      this.COUNTRY_ID = row.COUNTRY_ID;
      this.MOBILENO_CODE_ID = row.MOBILENO_CODE_ID;

    }

  }
  addApplicantDetails() {
    if (!this.WORKEXP_DETAIL_EMPLOYER.trim()) {
      return this.toastr.warning("Please enter details!");

      // this.btnDisabled = true;
    }
    const isValidStartDate = this.selectedYearStartWrk;
    const isValidEndDate = this.selectedYearEndWrk;
    const month = this.selectedMonthStartWrk?.toString().padStart(2, '0');
    const day = this.selectedDayStartWrk?.toString().padStart(2, '0');
    const month1 = this.selectedMonthEndWrk?.toString().padStart(2, '0');
    const day1 = this.selectedDayEndWrk?.toString().padStart(2, '0');
    this.WORKEXP_DETAIL_FROM_YEAR = `${isValidStartDate}-${month}-${day}`;
    this.WORKEXP_DETAIL_TO_YEAR = `${isValidEndDate}-${month1}-${day1}`;
    const partsOfStartDate = this.WORKEXP_DETAIL_FROM_YEAR?.split('-');
    const partsOfEndDate = this.WORKEXP_DETAIL_TO_YEAR?.split('-');
    if ((partsOfStartDate?.length !== 3 || partsOfStartDate.includes('undefined') || this.WORKEXP_DETAIL_FROM_YEAR == '-00-00'
      || partsOfStartDate.includes('NaN') || partsOfStartDate.includes(''))
      || (partsOfEndDate?.length !== 3 || partsOfEndDate.includes('undefined') || partsOfEndDate.includes('NaN') ||
        partsOfEndDate.includes('') || this.WORKEXP_DETAIL_TO_YEAR == '-00-00')) {
      this.toastr.error("Invalid Dates");
      return this.wrkExpDates();


    }
    if (this.wrlExperience !== -1) {
      // Editing an education item
      this.ApplicantData[this.wrlExperience] = {
        WORKEXP_DETAIL_EMPLOYER: this.WORKEXP_DETAIL_EMPLOYER,
        WORKEXP_DETAIL_DESIGNATION: this.WORKEXP_DETAIL_DESIGNATION,
        USER_ID: this.userId,
        WORKEXP_DETAIL_FROM_YEAR: this.WORKEXP_DETAIL_FROM_YEAR,
        WORKEXP_DETAIL_TO_YEAR: this.WORKEXP_DETAIL_TO_YEAR,
        WORKEXP_DETAIL_CREATEDBY: this.userId,
        WORKEXP_DETAIL_MODIFIEDBY: this.userId,
        WORKEXP_DETAIL_TNT_CODE: this.TenantCode,
        WORKEXP_DETAIL_ID: this.WORKEXP_DETAIL_ID
      };
    } else if (this.ApplicantData.length < 4) {
      // Adding a new education item
      const uniqueId = Math.floor(Math.random() * 100).toString().padStart(2, '0');
      this.ApplicantData.push({
        WORKEXP_DETAIL_EMPLOYER: this.WORKEXP_DETAIL_EMPLOYER,
        WORKEXP_DETAIL_DESIGNATION: this.WORKEXP_DETAIL_DESIGNATION,
        USER_ID: this.userId,
        WORKEXP_DETAIL_FROM_YEAR: this.WORKEXP_DETAIL_FROM_YEAR,
        WORKEXP_DETAIL_TO_YEAR: this.WORKEXP_DETAIL_TO_YEAR,
        WORKEXP_DETAIL_CREATEDBY: this.userId,
        WORKEXP_DETAIL_MODIFIEDBY: this.userId,
        WORKEXP_DETAIL_TNT_CODE: this.TenantCode,
        WORKEXP_DETAIL_ID: 0 || this.ApplicantData.forEach((e: any) => e.WORKEXP_DETAIL_ID),
        workId: parseInt(uniqueId)
      });
    }
    this.wrlExperience = -1;
    this.WORKEXP_DETAIL_EMPLOYER = ''; this.WORKEXP_DETAIL_DESIGNATION = '';
    this.WORKEXP_DETAIL_FROM_YEAR = ''; this.WORKEXP_DETAIL_TO_YEAR = ''; setTimeout(() => { this.wrkExpDates(); }, 100);
    if (this.userDetails?.lstt != 0) {
      this.updateWorkIds();
    }
  }
  updateWorkIds() {
    this.ApplicantData.forEach((apiItem) => {
      const matchingItemIndex = this.ApplicantData.findIndex((eduItem) => eduItem.WORKEXP_DETAIL_ID === apiItem.WORKEXP_DETAIL_ID);

      if (matchingItemIndex !== -1) {
        this.ApplicantData[matchingItemIndex].WORKEXP_DETAIL_ID = apiItem.WORKEXP_DETAIL_ID;
        this.ApplicantData[matchingItemIndex].WORKEXP_DETAIL_EMPLOYER = apiItem.WORKEXP_DETAIL_EMPLOYER;
        this.ApplicantData[matchingItemIndex].WORKEXP_DETAIL_DESIGNATION = apiItem.WORKEXP_DETAIL_DESIGNATION;
        this.ApplicantData[matchingItemIndex].WORKEXP_DETAIL_FROM_YEAR = apiItem.WORKEXP_DETAIL_FROM_YEAR;
        this.ApplicantData[matchingItemIndex].WORKEXP_DETAIL_TO_YEAR = apiItem.WORKEXP_DETAIL_TO_YEAR;
      }
    });
  }
  removeApplicantDetails(index: any) {
    if (index >= 0 && index < this.ApplicantData.length) {
      this.ApplicantData.splice(index, 1);
      this.WORKEXP_DETAIL_EMPLOYER = ''; this.WORKEXP_DETAIL_DESIGNATION = '';
      this.WORKEXP_DETAIL_FROM_YEAR = ''; this.WORKEXP_DETAIL_TO_YEAR = '';
      setTimeout(() => { this.wrkExpDates(); }, 100);

    }
  }
  addRefreeData() {
    let countryCode = this.country.find((e => e.COUNTRY_ID === parseInt(this.MOBILENO_CODE_ID)))?.COUNTRY_CONNECTING_CODE;
    if (!this.NAME.trim()) {
      return this.toastr.warning("Please enter Name!");
    } else if (!this.ORGANIZATION.trim()) {
      return this.toastr.warning("Please enter Organization!");
    } else if (!this.MOBILE_NUMBER.trim()) {
      return this.toastr.warning("Please enter Mobile Number!");
    } else if (this.COUNTRY_ID == '' || this.COUNTRY_ID == '0') {
      return this.toastr.warning("Please select the country!");
    } else {
      if (this.recommondation !== -1) {
        // Editing an education item
        this.RefreeData[this.recommondation] = {
          NAME: this.NAME,
          ORGANIZATION: this.ORGANIZATION,
          MOBILE_NUMBER: this.MOBILE_NUMBER,
          CITY_TOWN: this.CITY_TOWN,
          NATIONALITY: this.NATIONALITY,
          EMAIL_ADDRESS: this.EMAIL_ADDRESS,
          MODIFIED_BY: this.userId,
          CREATED_BY: this.userId,
          USER_ID: this.userId,
          TNT_CODE: this.TenantCode,
          RECOMMANDATION_ID: this.RECOMMANDATION_ID,
          MOBILENO_CODE_ID: this.MOBILENO_CODE_ID != '' ? this.MOBILENO_CODE_ID : 0,
          COUNTRY_CONNECTING_CODE: countryCode,
          COUNTRY_ID: this.COUNTRY_ID,
        };
      } else if (this.RefreeData.length < 4) {
        // Adding a new education item
        const uniqueId = Math.floor(Math.random() * 100).toString().padStart(2, '0');
        this.RefreeData.push({
          NAME: this.NAME,
          ORGANIZATION: this.ORGANIZATION,
          CITY_TOWN: this.CITY_TOWN,
          NATIONALITY: parseInt(this.NATIONALITY) || 0,
          EMAIL_ADDRESS: this.EMAIL_ADDRESS,
          MOBILE_NUMBER: this.MOBILE_NUMBER,
          MOBILENO_CODE_ID: this.MOBILENO_CODE_ID,
          COUNTRY_CONNECTING_CODE: countryCode,
          MODIFIED_BY: this.userId,
          CREATED_BY: this.userId,
          USER_ID: this.userId,
          TNT_CODE: this.TenantCode,
          RECOMMANDATION_ID: 0 || this.RefreeData.forEach((e: any) => e.RECOMMANDATION_ID),
          recomondationId: parseInt(uniqueId),
          COUNTRY_ID: this.COUNTRY_ID,
        });
      }
      this.recommondation = -1;
      this.NAME = ''; this.ORGANIZATION = ''; this.COUNTRY_ID = '';
      this.CITY_TOWN = ''; this.NATIONALITY = '';
      this.EMAIL_ADDRESS = ''; this.MOBILE_NUMBER = '';
      this.MOBILENO_CODE_ID = '';
      if (this.userDetails?.lst_recommandations != 0) {
        this.refreesIds();
      }
    }

  }
  refreesIds() {
    this.RefreeData.forEach((apiItem) => {
      const matchingItemIndex = this.RefreeData.findIndex((eduItem) => eduItem.RECOMMANDATION_ID === apiItem.RECOMMANDATION_ID);

      if (matchingItemIndex !== -1) {
        this.RefreeData[matchingItemIndex].RECOMMANDATION_ID = apiItem.RECOMMANDATION_ID;
        this.RefreeData[matchingItemIndex].NAME = apiItem.NAME;
        this.RefreeData[matchingItemIndex].ORGANIZATION = apiItem.ORGANIZATION;
        this.RefreeData[matchingItemIndex].CITY_TOWN = apiItem.CITY_TOWN;
        this.RefreeData[matchingItemIndex].NATIONALITY = apiItem.NATIONALITY;
        this.RefreeData[matchingItemIndex].EMAIL_ADDRESS = apiItem.EMAIL_ADDRESS;
        this.RefreeData[matchingItemIndex].MOBILE_NUMBER = apiItem.MOBILE_NUMBER;
        this.RefreeData[matchingItemIndex].MOBILENO_CODE_ID = apiItem.MOBILENO_CODE_ID;
        this.RefreeData[matchingItemIndex].COUNTRY_ID = apiItem.COUNTRY_ID;
      }
    });
  }
  removeRefreeData(index: any) {
    if (index >= 0 && index < this.RefreeData.length) {
      this.RefreeData.splice(index, 1);
      this.NAME = ''; this.ORGANIZATION = '';
      this.CITY_TOWN = ''; this.NATIONALITY = '';
      this.EMAIL_ADDRESS = ''; this.MOBILE_NUMBER = '';
      this.MOBILENO_CODE_ID = ''; this.COUNTRY_ID = '';
    }
  }
  getMeanGrades() {
    this.CommonService.postCall('AttainmentLevel/GetList', { "TENANT_CODE": this.TenantCode }).subscribe((res: any) => {
      this.grades = res;
    })
  }
  clearMonthDayWrk(item: any, tabName: string) {
    const clearStart = (tabName == "edu") ? () => {
      this.selectedMonthStart = ''; this.selectedDayStart = '';
    } : () => {
      this.selectedMonthStartWrk = ''; this.selectedDayStartWrk = '';
    };

    const clearEnd = (tabName == "edu") ? () => {
      this.selectedMonthEnd = ''; this.selectedDayEnd = '';
    } : () => {
      this.selectedMonthEndWrk = ''; this.selectedDayEndWrk = '';
    };
    item == 'start' ? clearStart() : clearEnd();
  }
  dropdownChanged(value: string) {
    this.showReasonTextbox = value === '5';
  }
  changeCountryCode(event: any) {
    this.MOBILENO_CODE_ID = event;
  }
  disablePersonalDetailsTab() {
    this.applicationProgram = false;
    this.personalDetailsSaved = false;
    this.biographicalInfoSaved = false;
    this.nextOfKin = false;
    this.educationDetails = false;
    this.workExp = false;
    this.recomondations = false;
    this.Referrals = false;
  }
  changeReferral(event) {
    if (event == '8' || event == 8) {
      this.showReferredBox = true;
      this.REFERRAL_NAME = this.userDetails?.result?.referral_name
    } else {
      this.showReferredBox = false;
    }
  }

}
